import React from "react";
import "../css/style.css";
import starTick from "../assests/images/start-tick.svg";

interface PProps {
  popTextContent?: any;
  btnText?: any;
  onclick?: any;
}

export default function PopModal({ popTextContent, btnText, onclick }: PProps) {
  return (
    <div className="pop-modal">
      <div className="pop-content">
        <img src={starTick} alt="" />
        {popTextContent}
        <button className="pop-btn" onClick={onclick}>
          {btnText}
        </button>
      </div>
    </div>
  );
}
