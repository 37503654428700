import React, { CSSProperties } from "react";
import "../css/style.css";
import "../css/responsive.css";

interface PProps {
  label?: string;
  type?: any;
  id?: string;
  value?: string;
  onChange?: any;
  placeholder?: string;
  rightIcon?: any;
  labelStyle?: CSSProperties;
  inputWrapStyle?: CSSProperties;
  error?: any;
  onblur?: any;
  inputStyle?: CSSProperties;
}

export default function Input1({
  label,
  type,
  id,
  value,
  onChange,
  placeholder,
  rightIcon,
  labelStyle,
  inputWrapStyle,
  error,
  onblur,
  inputStyle,
}: PProps) {
  return (
    <div className="input-cont1">
      <label htmlFor={id} className="input_label1" style={labelStyle}>
        {label}
      </label>
      <div
        className={`custom-input ${error ? "input-error" : ""}`}
        style={inputWrapStyle}
      >
        <input
          type={type}
          id={id}
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          className={`input1`}
          onBlur={onblur}
          style={inputStyle}
        />
        {rightIcon}
      </div>
      {error && <p className="error-text">{error}</p>}
    </div>
  );
}
