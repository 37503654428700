import React, { useState, useEffect } from "react";
import "../css/style.css";
import { Link, useNavigate } from "react-router-dom";
import star from "../assests/images/noto_star.svg";
import checkmark from "../assests/images/checkmark.svg";
import Cookies from "js-cookie";
import { PaymentElement } from "@stripe/react-stripe-js";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import {
  GetUserDetails,
  Subscribe,
  GetSubscriptionPlan,
} from "../RequestHandlers/User";

export default function SubscriptionScreen() {
  const navigate = useNavigate();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [activePage, setActivePage] = useState("monthly");
  const [id, setId] = useState("");
  const stripe = useStripe();
  const elements = useElements();


  const showMonthly = () => {
    setActivePage("monthly");
  };

  const showYearly = () => {
    setActivePage("yearly");
  };

  const handleSub = async (subscriptionType: string) => {
    if (!isLoggedIn) {
      window.location.href = "/loginScreen";
    } else {
      navigate("/StripePayment", { state: { subscriptionType } });
      // window.location.href = "/StripePayment";
      // window.location.href = "/paymentScreen";
    }
  };
  useEffect(() => {
    // Scroll to the top when the component mounts
    window.scrollTo(0, 0);

    const credentials = Cookies.get("webCook");
    if (credentials) {
      setIsLoggedIn(true);
    } else {
      setIsLoggedIn(false);
    }
  }, []);

  const subBenefit = [
    "Access subscriber-only content on TrendX",
    "Get blockchain investment recommendations",
    "Get timely and exclusive market insights",
    "Access to detailed blockchain case studies",
    "Read exclusive interviews with successful investors",
    "Achieve your goals in the blockchain space ",
  ];



  return (
    <section className="blogPostSection">
      <div className="blog-nav blog-post-nav">
        <Link
          to=" "
          onClick={(e) => {
            e.preventDefault();
            navigate(-1);
          }}
          className="blogArrow-back"
        >
          <svg
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M13.3333 31.6667C13.3333 30.4301 12.1117 28.5834 10.875 27.0334C9.285 25.0334 7.385 23.2884 5.20667 21.9567C3.57334 20.9584 1.59333 20.0001 9.53674e-07 20.0001M9.53674e-07 20.0001C1.59333 20.0001 3.575 19.0417 5.20667 18.0434C7.385 16.7101 9.285 14.9651 10.875 12.9684C12.1117 11.4167 13.3333 9.56675 13.3333 8.33341M9.53674e-07 20.0001L40 20.0001"
              stroke="white"
              stroke-width="2"
            />
          </svg>
        </Link>
      </div>
      <div className="sub-topper"></div>
      <div className="bpb-section">
        <div className="bpb-inner-wrap">
          <p className="sub-text-head sth">
            Subscribe to read premium research reports on TrendX
          </p>
          <div className="nav-switch-cont">
            <div className="nav-switch">
              <div
                onClick={showMonthly}
                className={
                  activePage === "monthly" ? "page page-active" : "page"
                }
              >
                <p>Pay monthly</p>
              </div>
              <div
                onClick={showYearly}
                className={
                  activePage === "yearly" ? "page2 page-active" : "page2"
                }
              >
                <p>Pay annually</p>
                <p>Save up to $48</p>
              </div>
            </div>
          </div>

          <div className={`active-page-cont ${activePage}`}>
            {activePage === "monthly" && (
              <div className="sub-page">
                <div className="modal-header">
                  <div className="mh-text-cont">
                    <div className="star-wrap">
                      <img src={star} alt="" />
                      <p>TX premium</p>
                    </div>
                    <p className="price">$4.99/month</p>
                  </div>
                  <button onClick={() => handleSub("monthly")}>
                    Subscribe
                  </button>
                </div>

                <div className="modal-body">
                  {subBenefit.map((item, index) => {
                    return (
                      <div
                        className="sub-ben"
                        style={{
                          marginBottom:
                            index === subBenefit.length - 1 ? 0 : "",
                        }}
                      >
                        <img src={checkmark} alt="" />
                        <p>{item}</p>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
            {activePage === "yearly" && (
              <div className="sub-page">
                <div className="modal-header">
                  <div className="mh-text-cont">
                    <div className="star-wrap">
                      <img src={star} alt="" />
                      <p>TX premium</p>
                    </div>
                    <p className="price">
                      {" "}
                      <span className="old-price">$59.88</span>$11.88/year
                    </p>
                  </div>
                  <button onClick={() => handleSub("yearly")}>Subscribe</button>
                </div>

                <div className="modal-body">
                  {subBenefit.map((item, index) => {
                    return (
                      <div
                        className="sub-ben"
                        style={{
                          marginBottom:
                            index === subBenefit.length - 1 ? 0 : "",
                        }}
                      >
                        <img src={checkmark} alt="" />
                        <p>{item}</p>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
}
