import React, { useEffect } from "react";
import "../css/style.css";
import FadeInSection from "../components/FadeIn";
import { Link } from "react-router-dom";

export default function TermsOfUse() {
  useEffect(() => {
    // Scroll to the top when the component mounts
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="black-bg">
      <section className="terms-use">
        <div className="abt-nav">
          <Link to="/">
            <svg
              width="40"
              height="40"
              viewBox="0 0 40 40"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M13.3333 31.6667C13.3333 30.4301 12.1117 28.5834 10.875 27.0334C9.285 25.0334 7.385 23.2884 5.20667 21.9567C3.57334 20.9584 1.59333 20.0001 9.53674e-07 20.0001M9.53674e-07 20.0001C1.59333 20.0001 3.575 19.0417 5.20667 18.0434C7.385 16.7101 9.285 14.9651 10.875 12.9684C12.1117 11.4167 13.3333 9.56675 13.3333 8.33341M9.53674e-07 20.0001L40 20.0001"
                stroke="white"
                stroke-width="2"
              />
            </svg>
          </Link>
        </div>
        <div className="top-space" style={{ width: "100%", height: 168 }}></div>
        <div className="abt-text-section">
          <div className="abt-text-wrap" style={{ marginBottom: 80 }}>
            <FadeInSection>
              <p className="abt-g-text">Terms of Service</p>
            </FadeInSection>
            <div className="policies-wrap">
              <p className="terms-p">1. Definitions</p>
              <p
                className="mt-text2 redHat-regular abt-p-text"
                style={{
                  textAlign: "left",
                  color: "rgba(255, 255, 255, 0.8)",
                }}
              >
                For the purpose of these Terms of Service ("Terms"), the
                following definitions apply: - *"Company," "we," "our," or "us"*
                refers to Trendx.
                <br /> - *"Platform"* refers to the Trendx website and its
                tokenization services.
                <br />- *"User," "you," or "your"* refers to any individual
                accessing or using our Platform. - *"Account"* refers to the
                User's registered account on the Platform.
                <br />- *"Token"* refers to the digital representation of a
                real-world asset.
              </p>
            </div>
            <div className="policies-wrap">
              <p className="terms-p">2. Changes</p>
              <p
                className="mt-text2 redHat-regular abt-p-text"
                style={{
                  textAlign: "left",
                  color: "rgba(255, 255, 255, 0.8)",
                }}
              >
                Trendx reserves the right to modify these Terms at any time. We
                will notify you of any changes by posting the updated Terms on
                our Platform. Continued use of the Platform after such changes
                constitutes acceptance of the new Terms.
              </p>
            </div>
            <div className="policies-wrap">
              <p className="terms-p">3. Digital Platform Terms</p>
              <p
                className="mt-text2 redHat-regular abt-p-text t-ptex"
                style={{
                  textAlign: "left",
                  color: "rgba(255, 255, 255, 0.8)",
                }}
              >
                Our Platform provides tokenization services allowing users to
                tokenize real-world assets. By using our Platform, you agree to
                comply with these Terms and all applicable laws and regulations.
              </p>
            </div>
            <div className="policies-wrap">
              <p className="terms-p">4. Network Device Requirements</p>
              <p
                className="mt-text2 redHat-regular abt-p-text t-ptex"
                style={{
                  textAlign: "left",
                  color: "rgba(255, 255, 255, 0.8)",
                }}
              >
                To access our Platform, you must have a compatible device,
                internet access, and certain necessary software. You are
                responsible for any fees associated with your access.
              </p>
            </div>
            <div className="policies-wrap">
              <p className="terms-p">5. Eligibility and Registration</p>
              <p
                className="mt-text2 redHat-regular abt-p-text t-ptex"
                style={{
                  textAlign: "left",
                  color: "rgba(255, 255, 255, 0.8)",
                }}
              >
                You must be at least 18 years old and capable of forming a
                binding contract in your jurisdiction to use our Platform. You
                agree to provide accurate and complete information during the
                registration process and to keep your information updated.
              </p>
            </div>
            <div className="policies-wrap">
              <p className="terms-p">6. Intellectual Property</p>
              <p
                className="mt-text2 redHat-regular abt-p-text t-ptex"
                style={{
                  textAlign: "left",
                  color: "rgba(255, 255, 255, 0.8)",
                }}
              >
                All content on the Platform, including text, graphics, logos,
                and software, is the property of Trendx or its licensors and is
                protected by intellectual property laws. You may not use any
                content without our prior written permission.
              </p>
            </div>
            <div className="policies-wrap">
              <p className="terms-p">7. Account</p>
              <p
                className="mt-text2 redHat-regular abt-p-text t-ptex"
                style={{
                  textAlign: "left",
                  color: "rgba(255, 255, 255, 0.8)",
                }}
              >
                You are responsible for maintaining the confidentiality of your
                account information, including your password, and for all
                activities that occur under your account. Notify us immediately
                of any unauthorized use of your account.
              </p>
            </div>
            <div className="policies-wrap">
              <p className="terms-p">8. Third-Party Accounts</p>
              <p
                className="mt-text2 redHat-regular abt-p-text t-ptex"
                style={{
                  textAlign: "left",
                  color: "rgba(255, 255, 255, 0.8)",
                }}
              >
                Our Platform may allow you to link or connect to third-party
                accounts. You agree to comply with the terms and conditions of
                any third-party accounts you link to our Platform.
              </p>
            </div>
            <div className="policies-wrap">
              <p className="terms-p">9. User Access Obligations</p>
              <p
                className="mt-text2 redHat-regular abt-p-text t-ptex"
                style={{
                  textAlign: "left",
                  color: "rgba(255, 255, 255, 0.8)",
                }}
              >
                You agree to use our Platform in compliance with these Terms and
                applicable laws. You are responsible for all actions taken under
                your account.
              </p>
            </div>
            <div className="policies-wrap">
              <p className="terms-p">
                10. Provision of Material and Information
              </p>
              <p
                className="mt-text2 redHat-regular abt-p-text t-ptex"
                style={{
                  textAlign: "left",
                  color: "rgba(255, 255, 255, 0.8)",
                }}
              >
                You are responsible for providing accurate and complete
                information when using our Platform. You grant Trendx the right
                to use, reproduce, and distribute any material or information
                you provide in connection with the Platform.
              </p>
            </div>
            <div className="policies-wrap">
              <p className="terms-p">11. Prohibited Uses</p>
              <p
                className="mt-text2 redHat-regular abt-p-text t-ptex"
                style={{
                  textAlign: "left",
                  color: "rgba(255, 255, 255, 0.8)",
                }}
              >
                You agree not to use our Platform for any unlawful purposes,
                including but not limited to: - Engaging in market manipulation
                or fraudulent activity.
                <br /> - Violating any applicable laws or regulations.
                <br /> - Distributing viruses or harmful software.
              </p>
            </div>
            <div className="policies-wrap">
              <p className="terms-p">12. Authorized Individuals</p>
              <p
                className="mt-text2 redHat-regular abt-p-text t-ptex"
                style={{
                  textAlign: "left",
                  color: "rgba(255, 255, 255, 0.8)",
                }}
              >
                You may authorize individuals to act on your behalf using your
                account. You are responsible for the actions of any authorized
                individuals and must ensure they comply with these Terms.
              </p>
            </div>
            <div className="policies-wrap">
              <p className="terms-p">13. Security</p>
              <p
                className="mt-text2 redHat-regular abt-p-text t-ptex"
                style={{
                  textAlign: "left",
                  color: "rgba(255, 255, 255, 0.8)",
                }}
              >
                We implement security measures to protect your information.
                However, we cannot guarantee absolute security. You are
                responsible for maintaining the security of your account
                credentials.
              </p>
            </div>
            <div className="policies-wrap">
              <p className="terms-p">14. Service and Other Notifications</p>
              <p
                className="mt-text2 redHat-regular abt-p-text t-ptex"
                style={{
                  textAlign: "left",
                  color: "rgba(255, 255, 255, 0.8)",
                }}
              >
                We may send you service-related notices, including updates,
                security alerts, and administrative messages. You agree to
                receive these communications electronically.
              </p>
            </div>
            <div className="policies-wrap">
              <p className="terms-p">15. Personal Information</p>
              <p
                className="mt-text2 redHat-regular abt-p-text t-ptex"
                style={{
                  textAlign: "left",
                  color: "rgba(255, 255, 255, 0.8)",
                }}
              >
                Your personal information is governed by our Privacy Policy,
                which explains how we collect, use, and protect your data.
              </p>
            </div>
            <div className="policies-wrap">
              <p className="terms-p">16. Market Makers</p>
              <p
                className="mt-text2 redHat-regular abt-p-text t-ptex"
                style={{
                  textAlign: "left",
                  color: "rgba(255, 255, 255, 0.8)",
                }}
              >
                We do not engage in market making or influence the price of
                tokens. You acknowledge that the value of tokens is subject to
                market conditions.
              </p>
            </div>
            <div className="policies-wrap">
              <p className="terms-p">
                17. Disclaimer and Risks of Use of Platform
              </p>
              <p
                className="mt-text2 redHat-regular abt-p-text t-ptex"
                style={{
                  textAlign: "left",
                  color: "rgba(255, 255, 255, 0.8)",
                }}
              >
                Using our Platform involves risks, including the potential loss
                of your investment. We do not guarantee the value of any tokens
                and are not responsible for any financial loss.
              </p>
            </div>
            <div className="policies-wrap">
              <p className="terms-p">18. Release / Indemnify</p>
              <p
                className="mt-text2 redHat-regular abt-p-text t-ptex"
                style={{
                  textAlign: "left",
                  color: "rgba(255, 255, 255, 0.8)",
                }}
              >
                You agree to release, indemnify, and hold harmless Trendx,
                subsidiaries, affiliates, partners, independent contractors from
                any claims, liabilities, damages, losses, or expenses arising
                from your use of the Platform or your breach of these Terms.
              </p>
            </div>
            <div className="policies-wrap">
              <p className="terms-p">
                19. Termination or Suspension in Part or in Whole
              </p>
              <p
                className="mt-text2 redHat-regular abt-p-text t-ptex"
                style={{
                  textAlign: "left",
                  color: "rgba(255, 255, 255, 0.8)",
                }}
              >
                We may terminate or suspend your account and access to our
                Platform, in whole or in part, immediately and without notice if
                you breach these Terms or for any other reason at our
                discretion.
              </p>
            </div>
            <div className="policies-wrap">
              <p className="terms-p">20. General</p>
              <p
                className="mt-text2 redHat-regular abt-p-text t-ptex"
                style={{
                  textAlign: "left",
                  color: "rgba(255, 255, 255, 0.8)",
                }}
              >
                These Terms constitute the entire agreement between you and
                Trendx regarding your use of the Platform. If any provision of
                these Terms is found to be invalid or unenforceable, the
                remaining provisions will remain in full force and effect.
              </p>
            </div>
            <div className="policies-wrap">
              <p className="terms-p">
                21. Governing Law and Dispute Resolution
              </p>
              <p
                className="mt-text2 redHat-regular abt-p-text t-ptex"
                style={{
                  textAlign: "left",
                  color: "rgba(255, 255, 255, 0.8)",
                }}
              >
                These Terms shall be governed by and construed in accordance
                with the laws of Nigeria. Any disputes arising out of or
                relating to these Terms shall be resolved through binding
                arbitration in Nigeria, in accordance with Arbitration Rules of
                the International Arbitration Centre for the time being in
                force, which rules are deemed to be incorporated by reference in
                this clause.
                <br /> The Tribunal shall consist of one (1) arbitrator. The
                language of the arbitration shall be English. The seat of the
                arbitration shall be Singapore. Any award is final and may be
                enforced in any court of competent jurisdiction. The parties
                shall duly and punctually perform their obligations hereunder
                pending the issuance of the arbitral award.
              </p>
            </div>
            <div className="policies-wrap">
              <p className="terms-p">22. Contacting Us</p>
              <p
                className="mt-text2 redHat-regular abt-p-text t-ptex"
                style={{
                  textAlign: "left",
                  color: "rgba(255, 255, 255, 0.8)",
                }}
              >
                You can contact Trendx regarding these Terms, the site, or the
                Platform at: Trendx, 1007 N Orange St. 4th Floor, Wilmington,
                Delaware, USA. Email: support@trendx.app
              </p>
            </div>
            <div className="policies-wrap">
              <p className="terms-p">23. Securities Disclaimer</p>
              <p
                className="mt-text2 redHat-regular abt-p-text t-ptex"
                style={{
                  textAlign: "left",
                  color: "rgba(255, 255, 255, 0.8)",
                }}
              >
                The tokens available on our Platform are not registered with any
                securities regulatory authority. The use of our Platform does
                not constitute an offer to sell or a solicitation of an offer to
                buy securities.
              </p>
            </div>
            <div className="policies-wrap">
              <p className="terms-p">
                24. No Professional or Investment Advice
              </p>
              <p
                className="mt-text2 redHat-regular abt-p-text t-ptex"
                style={{
                  textAlign: "left",
                  color: "rgba(255, 255, 255, 0.8)",
                }}
              >
                Trendx does not provide any professional, financial, or
                investment advice. Any information provided on the Platform is
                for informational purposes only and should not be construed as
                professional advice. You should consult with a qualified
                professional before making any investment decisions.
              </p>
            </div>
            <p className="last-p">
              By using our Services, you acknowledge that you have read,
              understood, and agree to be bound by these Terms and our Privacy
              Policy.
            </p>
          </div>
        </div>
      </section>
    </div>
  );
}
