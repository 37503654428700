import React, { CSSProperties } from "react";
import "../css/animations.css";

interface PProps {
  loader?: boolean;
  loaderStyle?: CSSProperties;
}

export default function Loader({ loader, loaderStyle }: PProps) {
  return loader ? (
    <div className="loader-container" style={loaderStyle}>
      <div id="load"></div>
    </div>
  ) : null;
}
