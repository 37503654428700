import React, { useState, useEffect } from "react";
import "../css/style.css";
import { Link, useNavigate } from "react-router-dom";
import txLogo from "../assests/images/tx-logo.png";
import emptyIcon from "../assests/images/emptyIcon.svg";
import { GetUserNotifications } from "../RequestHandlers/Notification";
import { formatDate } from "../components/FormatDate";

export default function MobileNOtificationScreen() {
  const navigate = useNavigate();
  const [scrollPosition, setScrollPosition] = useState(0);
  const [notifications, setNotifications] = useState([]);

  const handleScroll = () => {
    setScrollPosition(window.pageYOffset);
  };
  const userNotifications = async () => {
    try {
      const noti = await GetUserNotifications();
      setNotifications(noti.items);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    userNotifications();
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  // @ts-ignore
  return (
    <div className="mNoti-section">
      <div
        className={
          scrollPosition > 1
            ? notifications.length > 0
              ? "mobile-noti-nav mobile-noti-nav-bdr"
              : "mobile-noti-nav"
            : "mobile-noti-nav"
        }
      >
        <Link
          to=" "
          onClick={(e) => {
            e.preventDefault();
            navigate(-1);
          }}
          className="blogArrow-back"
          style={{ marginTop: 0, paddingTop: 0 }}
        >
          <svg
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M13.3333 31.6667C13.3333 30.4301 12.1117 28.5834 10.875 27.0334C9.285 25.0334 7.385 23.2884 5.20667 21.9567C3.57334 20.9584 1.59333 20.0001 9.53674e-07 20.0001M9.53674e-07 20.0001C1.59333 20.0001 3.575 19.0417 5.20667 18.0434C7.385 16.7101 9.285 14.9651 10.875 12.9684C12.1117 11.4167 13.3333 9.56675 13.3333 8.33341M9.53674e-07 20.0001L40 20.0001"
              stroke="white"
              stroke-width="2"
            />
          </svg>
        </Link>
        <p
          className="nt-head"
          style={{ display: notifications.length === 0 ? "none" : "" }}
        >
          Notifications
        </p>
      </div>
      <div className="noti-topper"></div>
      <div className="mobile-noti-body">
        {notifications.length === 0 ? (
          <div className="card-empty noti-empty">
            <img src={emptyIcon} alt="" />
            <h4>No notifications</h4>
            <p>
              We’ll let you know when there’s <br /> anything new!
            </p>
          </div>
        ) : (
          <>
            {/* @ts-ignore */}
            {notifications.map((item, index) => {
              return (
                <div
                  className="notification-item noti-item"
                  // onClick={() => setShowNotification(false)}
                  style={{
                    border: index === notifications.length - 1 ? "none" : "",
                    marginBottom:
                      index === notifications.length - 1 ? "none" : "",
                  }}
                >
                  <img src={txLogo} alt="" />
                  <div className="text-date-wrap noti-tdw">
                    {/* @ts-ignore */}
                    <p>{item.title}</p>
                    {/* @ts-ignore */}
                    <p>{formatDate(item.created_at)}</p>
                  </div>
                </div>
              );
            })}
          </>
        )}
      </div>
    </div>
  );
}
