import React from "react";

interface PProps {
  page?: number;
  totalPages?: number;
  setPage?: any;
}

const Pagination = ({ page, totalPages, setPage }: PProps) => {
  const renderPaginationButtons = () => {
    const buttons = [];
    // @ts-ignore
    const startPage = Math.max(page - 1, 1);
    // @ts-ignore
    const endPage = Math.min(startPage + 2, totalPages);

    for (let i = startPage; i <= endPage; i++) {
      buttons.push(
        <button
          key={i}
          className={`pagination-button ${
            page === i ? "active-pagination" : ""
          }`}
          onClick={() => setPage(i)}
        >
          {i}
        </button>
      );
    }

    return (
      <div className="pagination-container">
        <button
          className="pagination-arrow"
          disabled={startPage === 1}
          //@ts-ignore
          onClick={() => setPage(page - 1)}
          style={{
            cursor: startPage === 1 ? "not-allowed" : "pointer",
            display: startPage === 1 ? "none" : "",
          }}
        >
          <svg
            width={16}
            height={16}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8 4L16 12L8 20"
              stroke={startPage === 1 ? "#ffffff4d" : "white"}
              strokeWidth="3"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </button>
        {buttons}
        {endPage === totalPages ? "" : <p className="p-dots">...</p>}
        <button
          className={`pagination-arrow ${
            endPage === totalPages ? "" : "hover"
          }`}
          disabled={endPage === totalPages}
          //@ts-ignore
          onClick={() => setPage(page + 1)}
          style={{
            cursor: endPage === totalPages ? "not-allowed" : "pointer",
            display: endPage === totalPages ? "none" : "",
          }}
        >
          {/* <svg
            width="16"
            height="16"
            viewBox="0 0 38 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M3.00012 15H8.00012M35.0001 15L23.0001 3M35.0001 15L23.0001 27M35.0001 15H14.0001"
              stroke={endPage === totalPages ? "#ffffff4d" : "white"}
              strokeWidth="4.28571"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg> */}
          <svg
            width={16}
            height={16}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8 4L16 12L8 20"
              stroke={endPage === totalPages ? "#ffffff4d" : "white"}
              strokeWidth="3"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </button>
      </div>
    );
  };

  return renderPaginationButtons();
};

export default Pagination;
