import React, { useState, useEffect, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import "../css/style.css";
import Input1 from "../components/Input1";
import eye from "../assests/images/eye.svg";
import eyeOff from "../assests/images/eyeOff.svg";
import { Login } from "../RequestHandlers/Authentication";
import Cookies from "js-cookie";
import { CredentailsContext } from "../RequestHandlers/CredentailsContext";
import Toast, { handleToast } from "../components/Toast";
import Loader from "../components/Loader";

export default function LoginScreen() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [errors, setErrors] = useState({});
  const [pError, setPErrors] = useState({});
  const [loader, setLoader] = useState(false);
  const [touched, setTouched] = useState({ email: false, password: false });
  const { storedCredentails, setStoredCredentails } =
    useContext(CredentailsContext);
  const navigate = useNavigate();

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  useEffect(() => {
    // Scroll to the top when the component mounts
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (touched.email) {
      validateEmail();
    }
  }, [email, touched.email]);

  useEffect(() => {
    if (touched.password) {
      validatePassword();
    }
  }, [password, touched.password]);

  const validateEmail = () => {
    const newErrors = {};
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!email) {
      // @ts-ignore
      newErrors.email = "Email is required";
    } else if (!emailRegex.test(email)) {
      // @ts-ignore
      newErrors.email = "Email is invalid";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const validatePassword = () => {
    const newErrors = {};
    if (!password) {
      // @ts-ignore
      newErrors.password = "Password is required";
    }

    setPErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  useEffect(() => {
    const storedCredentials = Cookies.get("webCook");
    if (storedCredentials) {
      navigate("/blogDashboard");
    }
  }, [navigate]);

  const persistLogin = (credentials:any) => {
    try {
      Cookies.set("webCook", JSON.stringify(credentials), { expires: 7 });
      // @ts-ignore
      setStoredCredentails(credentials);
      setTimeout(() => {
        navigate("/blogDashboard");
      }, 3000);
    } catch (err) {
      console.error("Error storing credentials:", err);
    }
  };

  const login = async () => {
    setLoader(true);
    try {
      const body = {
        email: email,
        password: password,
      };
      const loginUser = await Login(body);
      if (loginUser.token) {
        setLoader(false);
        handleToast("Login successful", "success");
        persistLogin(loginUser.token);
      } else {
        setLoader(false);
        handleToast(loginUser.message, "error");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleFormSubmit = (e:any) => {
    e.preventDefault();
    const emailValid = validateEmail();
    const passwordValid = validatePassword();

    setTouched({ email: true, password: true });

    if (emailValid && passwordValid) {
      login();
    }
  };

  return (
    <section className="login-section">
      <Loader loader={loader} />
      <div className="login-nav">
        <Link
          to=" "
          onClick={(e) => {
            e.preventDefault();
            navigate(-1);
          }}
        >
          <svg
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M13.3333 31.6667C13.3333 30.4301 12.1117 28.5834 10.875 27.0334C9.285 25.0334 7.385 23.2884 5.20667 21.9567C3.57334 20.9584 1.59333 20.0001 9.53674e-07 20.0001M9.53674e-07 20.0001C1.59333 20.0001 3.575 19.0417 5.20667 18.0434C7.385 16.7101 9.285 14.9651 10.875 12.9684C12.1117 11.4167 13.3333 9.56675 13.3333 8.33341M9.53674e-07 20.0001L40 20.0001"
              stroke="white"
              stroke-width="2"
            />
          </svg>
        </Link>
      </div>
      <div className="login-topper"></div>
      <div className="login-body">
        <div className="login-wrap">
          <p className="l-head-text">Log in with TrendX</p>
          <p className="l-body-text">
            To bookmark or subscribe to our premium research reports, you'll need to Log in to your Trendx account. If you don’t have one, you will be redirected to create one.
          </p>
          <form onSubmit={handleFormSubmit}>
            <div className="input-wrap1">
              <Input1
                label="Email address"
                type="text"
                value={email}
                onChange={(e:any) => {
                  setEmail(e.target.value);
                  setTouched({ ...touched, email: true });
                }}
                // @ts-ignore
                error={touched.email && errors.email}
              />
            </div>
            <div className="input-wrap2">
              <Input1
                label="Password"
                type={passwordVisible ? "text" : "password"}
                value={password}
                onChange={(e:any) => {
                  setPassword(e.target.value);
                  setTouched({ ...touched, password: true });
                }}
                // @ts-ignore
                error={touched.password && pError.password}
                rightIcon={
                  <div
                    style={{
                      width: "10%",
                      height: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-end",
                    }}
                  >
                    <button
                      style={{
                        background: "none",
                        border: "none",
                        cursor: "pointer",
                      }}
                      onClick={(e) => {
                        e.preventDefault();
                        togglePasswordVisibility();
                      }}
                    >
                      <img
                        src={passwordVisible ? eyeOff : eye}
                        alt=""
                        className="eye-icon"
                      />
                    </button>
                  </div>
                }
              />
              <div className="cp-cont">
                <button
                  className="cp"
                  onClick={() => navigate("/fogotPassword")}
                >
                  Forgot Password?
                </button>
              </div>
            </div>
            <div className="signIn-btn" onClick={handleFormSubmit}>
              Continue
            </div>
            <p className="regisLink">
              New to Trendx?{" "}
              <span>
                <Link
                  to={"/registerScreen"}
                  style={{ color: "#D4F36B", textDecoration: "none" }}
                >
                  Create account
                </Link>
              </span>{" "}
            </p>
          </form>
        </div>
      </div>
      <Toast />
    </section>
  );
}
