import React, { CSSProperties } from "react";
import { useInView } from "react-intersection-observer";
import "../css/animations.css";

interface PProps {
  direction?: "left" | "right" | "top" | "bottom";
  children?: any;
}

const SlideInComponent = ({ direction = "left", children }: PProps) => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  return (
    <div
      ref={ref}
      className={`hidden ${inView ? `slide-in-${direction}` : ""}`}
    >
      {children}
    </div>
  );
};

export default SlideInComponent;
