import React from "react";
import "../css/style.css";

interface PProps {
  onclick?: any;
  backgroundColor?: any;
  strokeOpacity?: any;
  disabled?: any;
}

export default function LeftBtn({
  onclick,
  backgroundColor,
  strokeOpacity,
  disabled,
}: PProps) {
  return (
    <button
      onClick={onclick}
      disabled={disabled}
      style={{ border: "none", background: "none" }}
    >
      <svg
        width="80"
        height="80"
        viewBox="0 0 80 80"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="flow-btn"
        style={{ color: "red" }}
      >
        <rect width="80" height="80" rx="40" fill={backgroundColor} />
        <path
          d="M55.9999 40H50.9999M23.9999 40L35.9999 52M23.9999 40L35.9999 28M23.9999 40H44.9999"
          stroke="white"
          stroke-opacity={strokeOpacity}
          stroke-width="4.28571"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </button>
  );
}
