import React, { useState, useEffect, useRef } from "react";
import "../css/style.css";
import SideBar from "../components/SideBar";
import ProfileNav from "../components/ProfileNav";
import postImg1 from "../assests/images/postImg1.svg";
import postImg2 from "../assests/images/postImg2.svg";
import postImg3 from "../assests/images/postImg3.svg";
import { Link, useNavigate } from "react-router-dom";
import FadeInSection from "../components/FadeIn";
import paperClip from "../assests/images/papperclip.svg";
import emptyDoc from "../assests/images/empty-doc.svg";
import Logout, { handleLogout } from "../components/Logout";
import { GetBookMarks, GetBlogCategories } from "../RequestHandlers/Blogs";
import { useMediaQuery } from "react-responsive";
import { formatDate } from "../components/FormatDate";

export default function BookmarkScreen() {
  const [activeTab, setActiveTab] = useState("All");
  const [blogCat, setBlogCat] = useState([]);
  const [catID, setCatID] = useState(0);
  const [allBookmarks, setAllBookmarks] = useState([]);
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const containerRef = useRef(null);
  const [isOverflowing, setIsOverflowing] = useState(false);

  const scrollAmount = 100; // Adjust scroll amount as needed
  const isSafari = () => {
    return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  };

  const scrollLeft = () => {
    if (containerRef.current) {
      // @ts-ignore
      containerRef.current.scrollBy({
        top: 0,
        left: -scrollAmount,
        behavior: isSafari() ? "auto" : "smooth",
      });
    }
  };

  const scrollRight = () => {
    if (containerRef.current) {
      // @ts-ignore
      containerRef.current.scrollBy({
        top: 0,
        left: scrollAmount,
        behavior: isSafari() ? "auto" : "smooth",
      });
    }
  };

  const checkOverflow = () => {
    if (containerRef.current) {
      const isOverflowing =
        // @ts-ignore
        containerRef.current.scrollWidth > containerRef.current.clientWidth;
      setIsOverflowing(isOverflowing);
    }
  };

  useEffect(() => {
    checkOverflow();
    window.addEventListener("resize", checkOverflow);

    const observer = new MutationObserver(() => {
      checkOverflow();
    });

    if (containerRef.current) {
      observer.observe(containerRef.current, {
        childList: true,
        subtree: true,
      });
    }

    return () => {
      window.removeEventListener("resize", checkOverflow);
      observer.disconnect();
    };
  }, []);

  // @ts-ignore
  const truncateText = (text, maxLength) => {
    let charCount = 0;
    let truncatedText = "";

    for (let char of text) {
      if (char !== " ") {
        charCount++;
      }
      if (charCount <= maxLength) {
        truncatedText += char;
      } else {
        truncatedText += "...";
        break;
      }
    }

    return truncatedText;
  };
  const getBlogCategories = async () => {
    try {
      const blogCategories = await GetBlogCategories();
      // @ts-ignore
      const allCategory = { id: null, name: "All" }; // Define your "All" category
      // @ts-ignore
      setBlogCat([allCategory, ...blogCategories]);
    } catch (error) {
      console.log(error);
    }
  };

  const getBookMarks = async () => {
    try {
      const fetchAllBLogs = await GetBookMarks(catID);
      setAllBookmarks(fetchAllBLogs);
    } catch (error) {
      console.log(error);
    }
  };
  // @ts-ignore
  const handleReadMoreClick = (slug, category) => {
    navigate("/blogPostScreen", { state: { slug, category } }); // Pass the blog item as state
  };
  useEffect(() => {
    // Scroll to the top when the component mounts
    window.scrollTo(0, 0);
    getBlogCategories();
    getBookMarks();
  }, []);

  useEffect(() => {
    getBookMarks();
  }, [catID]);

  return (
    <section className="dashboard-layout">
      <div className="left-layout">
        <SideBar logoutClick={() => handleLogout()} />
      </div>
      <div className="left-spacer"></div>
      <div className="body-layout">
        <div className="profile-nav-cont">
          <ProfileNav logoutClick={handleLogout} />
        </div>
        {/* <div className="profile-nav-topper"></div> */}
        <div className="main-body">
          <p className="g-text2 bd-text">Bookmarks</p>
          <div className="db-tab-link-cont" style={{ marginBottom: 0 }}>
            {/* {isOverflowing && (
              <button
              className="movement-arrow"
                style={{
                  background: "none",
                  border: "none",
                  color: "#fff",
                  fontSize: 20,
                  marginRight: 30,
                }}
                onClick={scrollLeft}
              >
                ←
              </button>
            )} */}
            <div className="db-tab-inner" ref={containerRef}>
              {blogCat.map((item, index) => (
                <p
                  key={index}
                  style={{
                    // @ts-ignore
                    color: "white",
                    // @ts-ignore
                    fontWeight: activeTab === item.name ? 600 : 400,
                    cursor: "pointer",
                    marginRight: index === blogCat.length - 1 ? 0 : "",
                  }}
                  className="tab-links"
                  onClick={() => {
                    // @ts-ignore
                    setActiveTab(item.name);
                    // @ts-ignore
                    setCatID(item.id);
                  }}
                >
                  {/* @ts-ignore */}
                  {item.name}
                </p>
              ))}
            </div>
            {/* {isOverflowing && (
              <button
               className="movement-arrow"
                style={{
                  background: "none",
                  border: "none",
                  color: "#fff",
                  fontSize: 20,
                  marginLeft: 30,
                }}
                onClick={scrollRight}
              >
                →
              </button>
            )} */}
          </div>

          {allBookmarks.length === 0 ? (
            <div className="empty-sec">
              <img src={emptyDoc} alt="" />
              <p className="pop-text1">No bookmarks</p>

              {activeTab === "All" ? (
                <p className="pop-text2">
                  Looks like you’ve not saved any reports to <br /> your
                  bookmarks yet.
                </p>
              ) : (
                <p className="pop-text2">
                  Looks like you’ve not saved any reports <br /> in this
                  category
                </p>
              )}
            </div>
          ) : (
            <div
              className="main-blog-section blog-section1"
              style={
                {
                  // paddingTop: 0,
                }
              }
            >
              <>
                {/* @ts-ignore */}
                {allBookmarks.map((item, index) => {
                  // @ts-ignore
                  const parser = new DOMParser();
                  // @ts-ignore
                  const doc = parser.parseFromString(item.body, "text/html");
                  let firstParagraph = doc.querySelector("p");
                  const firstImag = doc.querySelector("img");

                  // Check if the first paragraph is empty or contains only whitespace
                  if (
                    !firstParagraph ||
                    // @ts-ignore
                    firstParagraph.textContent.trim() === ""
                  ) {
                    const paragraphs = doc.querySelectorAll("p");
                    if (paragraphs.length > 1) {
                      firstParagraph = paragraphs[1];
                    }
                  }

                  return (
                    <FadeInSection>
                      <div
                        className="blog-content-card db-content-card"
                        style={{ cursor: "pointer" }}
                        onClick={(e) => {
                          e.preventDefault();
                          handleReadMoreClick(
                            // @ts-ignore
                            item.slug,
                            // @ts-ignore
                            item.category.name
                          );
                        }}
                      >
                        <div className="blog-card-header db-header">
                          <div className="bch-wrap bch-wrap1">
                            <img src={paperClip} alt="" />
                            {/* @ts-ignore */}
                            <p className="bch-p bch-p1">{item.category.name}</p>
                          </div>
                          <p className="db-date">
                            {/* @ts-ignore */}
                            {formatDate(item.created_at)}
                          </p>
                        </div>
                        <div className="line-seperator db-seperator"></div>
                        {/* @ts-ignore */}
                        <p className="blog-title blog-title1">{item.title}</p>
                        <p className="blog-post-body bp-body1">
                          {firstParagraph
                            ? truncateText(firstParagraph.textContent, 120)
                            : ""}{" "}
                          <span>
                            <Link
                              to={""}
                              onClick={(e) => {
                                e.preventDefault();
                                handleReadMoreClick(
                                  // @ts-ignore
                                  item.slug,
                                  // @ts-ignore
                                  item.category.name
                                );
                              }}
                              className="readMore"
                            >
                              Read More
                            </Link>
                          </span>
                        </p>

                        <img
                          // @ts-ignore
                          src={firstImag ? firstImag.src : ""}
                          className="b-postImg"
                          alt=""
                        />
                      </div>
                    </FadeInSection>
                  );
                })}
              </>
            </div>
          )}
        </div>
      </div>
      <Logout />
    </section>
  );
}
