import React, { useState, useRef, useEffect } from "react";
import "../css/style.css";

interface PProps {
  value?: any;
  onChange?: any;
  placeholder?: string;
  id?: string;
  label?: string;
  error?: string;
}

export default function Textarea({
  value,
  onChange,
  placeholder,
  id,
  label,
  error,
}: PProps) {
  const textareaRef = useRef(null);
  const [textareaValue, setTextareaValue] = useState(value);

  useEffect(() => {
    const textarea = textareaRef.current;
    // @ts-ignore
    textarea.style.height = "auto";
    // @ts-ignore
    textarea.style.height = `${textarea.scrollHeight}px`;
  }, [textareaValue]);

  const handleChange = (e: any) => {
    setTextareaValue(e.target.value);
    if (onChange) {
      onChange(e);
    }
  };

  return (
    <div className="input-cont">
      <label htmlFor={id} className="input_label">
        {label}
      </label>
      <textarea
        ref={textareaRef}
        value={textareaValue}
        onChange={handleChange}
        placeholder={placeholder}
        className={`textarea-input  ${error ? "txt-error" : ""}`}
      />
      {error && <p className="error-text">{error}</p>}
    </div>
  );
}
