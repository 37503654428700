import React, { useState, useContext } from "react";
import "../css/style.css";
import gX from "../assests/images/gx.svg";
import Cookies from "js-cookie";
import { CredentailsContext } from "../RequestHandlers/CredentailsContext";
import { useNavigate } from "react-router-dom";

interface PProp {
  cancel?: any;
  logOut?: any;
}

let showLogoutFn: () => void;

export function handleLogout() {
  showLogoutFn();
}

export default function Logout({ cancel, logOut }: PProp) {
  const [show, setShow] = useState(false);
  const navigate = useNavigate();
  const { setStoredCredentails } = useContext(CredentailsContext);

  const handleLogout = () => {
    // Clear cookies
    Cookies.remove("webCook");
    
    // Clear context or any other state
    // @ts-ignore
    setStoredCredentails(null);
    navigate("/");
    setShow(false);
  };

  showLogoutFn = () => {
    setShow(true);
  };

  return show ? (
    <div className="logoutPop">
      <div className="logout-card">
        <img src={gX} alt="" onClick={() => setShow(false)} />
        <p className="log-text1">Log out?</p>
        <p className="log-text2">Are you ready to log out now?</p>
        <div className="log-btn-wrap">
          <button className="log-control-btn" onClick={() => setShow(false)}>
            Go back
          </button>
          <button
            className="log-control-btn"
            style={{ background: "#fff" }}
            onClick={handleLogout}
          >
            Logout
          </button>
        </div>
      </div>
    </div>
  ) : (
    <></>
  );
}
