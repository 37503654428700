import React from 'react';

function Menu() {
    return (
        <div className='modal-section'>
            
        </div>
    );
}

export default Menu;