import React, { useState, useEffect } from "react";
import "../css/Toast.css"; // Import the CSS file
import sucTick from "../assests/images/sucTick.svg";
import unSucTick from "../assests/images/unSucTick.svg";
import SlideInComponent from "./SlideInComponent";
let showToastFn: any;

export function handleToast(text: any, type = "success") {
  showToastFn(text, type);
}

export default function Toast() {
  const [show, setShow] = useState(false);
  const [text, setText] = useState("");
  const [type, setType] = useState("success");

  useEffect(() => {
    showToastFn = (text: string, type: string) => {
      setText(text);
      setType(type);
      setShow(true);
      setTimeout(() => {
        setShow(false);
      }, 5000);
    };
  }, []);

  function capitalizeFirstLetterOfEachSentence(text: any) {
    const sentenceEndings = /([.!?])\s*/;
    return text
      .split(sentenceEndings)
      .map((segment: any, index: any) => {
        if (index % 2 === 0) {
          return (
            segment.charAt(0).toUpperCase() + segment.slice(1).toLowerCase()
          );
        }
        return segment;
      })
      .join("");
  }

  return show ? (
    <div className="toast-container">
      <SlideInComponent direction="right">
        <div className={`toast-card ${type} ${show ? "show" : ""}`}>
          {/* <div className="icon-wrapper">
          </div> */}
          <img
            src={type === "error" ? unSucTick : sucTick}
            alt=""
            className="icon"
          />
          <h1 className="toast-header-text">
            {capitalizeFirstLetterOfEachSentence(text)}
          </h1>
        </div>
      </SlideInComponent>
    </div>
  ) : null;
}
