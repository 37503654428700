import React, { useState, useEffect } from "react";
import "../css/style.css";
import SideBar from "../components/SideBar";
import ProfileNav from "../components/ProfileNav";
import { Link, useLocation, useNavigate } from "react-router-dom";
import FadeInSection from "../components/FadeIn";
import paperClip from "../assests/images/papperclip.svg";
import emptyDoc from "../assests/images/empty-doc.svg";
import Logout, { handleLogout } from "../components/Logout";
import checkmark from "../assests/images/checkmark.svg";
import dropDown from "../assests/images/dropdown.svg";
import dropUp from "../assests/images/dropUp.svg";
import star from "../assests/images/noto_star.svg";
import PopModal from "../components/PopModal";
import { useMediaQuery } from "react-responsive";

export default function ReactivatePlanScreen() {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const navigate = useNavigate();
  const location = useLocation();
  const fromSupport = location.state?.fromBlog || false;
  const [plan, setPlan] = useState("");
  const [pop, setPop] = useState(false);
  const [activePage, setActivePage] = useState("monthly");
  const [isCurrentPlan, setIsCurrentPlan] = useState(true);

  const showMonthly = () => {
    setActivePage("monthly");
    setTimeout(() => {
      setPop(true);
    }, 3000);
  };

  const showYearly = () => {
    setActivePage("yearly");
    setTimeout(() => {
      setPop(true);
    }, 3000);
  };
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const handleDropdownToggle = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  useEffect(() => {
    // Example: Reset scroll position when navigating to this page
    window.scrollTo(0, 0);
  }, []);
  const subBenefit = [
    "Access subscriber-only content on TrendX",
    "Get blockchain investment recommendations",
    "Get timely and exclusive market insights",
    "Access to detailed blockchain case studies",
    "Read exclusive interviews with successful investors",
    "Achieve your goals in the blockchain space ",
  ];

  return (
    <section className="dashboard-layout">
      <div className="left-layout">
        <SideBar logoutClick={() => handleLogout()} fromBlog={fromSupport} />
      </div>
      <div className="left-spacer"></div>
      <div className="body-layout" style={{ height: "auto" }}>
        <div className="profile-nav-cont">
          {isMobile ? (
            <>
              <div className="sub-profile-nav">
                {" "}
                <Link
                  to=" "
                  onClick={(e) => {
                    e.preventDefault();
                    navigate(-1);
                  }}
                  className="blogArrow-back"
                  style={{ marginTop: 0, paddingTop: 0 }}
                >
                  <svg
                    width="40"
                    height="40"
                    viewBox="0 0 40 40"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M13.3333 31.6667C13.3333 30.4301 12.1117 28.5834 10.875 27.0334C9.285 25.0334 7.385 23.2884 5.20667 21.9567C3.57334 20.9584 1.59333 20.0001 9.53674e-07 20.0001M9.53674e-07 20.0001C1.59333 20.0001 3.575 19.0417 5.20667 18.0434C7.385 16.7101 9.285 14.9651 10.875 12.9684C12.1117 11.4167 13.3333 9.56675 13.3333 8.33341M9.53674e-07 20.0001L40 20.0001"
                      stroke="white"
                      stroke-width="2"
                    />
                  </svg>
                </Link>
              </div>
            </>
          ) : (
            <ProfileNav />
          )}
        </div>
        {/* <div className="profile-nav-topper"></div> */}
        <div className="main-body ppr-sec">
          <p className="pro-fp re-text">Re-activate subscription</p>
          <div className="asc-wrap">
            <div className="nav-switch-cont">
              <div className="nav-switch">
                <div
                  onClick={showMonthly}
                  className={
                    activePage === "monthly" ? "page page-active" : "page"
                  }
                >
                  <p>Pay monthly</p>
                </div>
                <div
                  onClick={showYearly}
                  className={
                    activePage === "yearly" ? "page2 page-active" : "page2"
                  }
                >
                  <p>Pay annually</p>
                  <p>Save up to $48</p>
                </div>
              </div>
            </div>

            <div className={`active-page-cont ${activePage}`}>
              {activePage === "monthly" && (
                <div className="sub-page">
                  <div className="modal-header">
                    <div className="mh-text-cont">
                      <div className="star-wrap">
                        <img src={star} alt="" />
                        <p>TX premium</p>
                      </div>
                      <p className="price">$4.99/month</p>
                    </div>
                    <button>Re-activate</button>
                  </div>

                  <div className="modal-body">
                    {subBenefit.map((item, index) => {
                      return (
                        <div
                          className="sub-ben"
                          style={{
                            marginBottom:
                              index === subBenefit.length - 1 ? 0 : "",
                          }}
                        >
                          <img src={checkmark} alt="" />
                          <p>{item}</p>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
              {activePage === "yearly" && (
                <div className="sub-page">
                  <div className="modal-header">
                    <div className="mh-text-cont">
                      <div className="star-wrap">
                        <img src={star} alt="" />
                        <p>TX premium</p>
                      </div>
                      <p className="price">
                        {" "}
                        <span className="old-price">$59.88</span>$11.88/year
                      </p>
                    </div>
                    <button>Re-activate</button>
                  </div>

                  <div className="modal-body">
                    {subBenefit.map((item, index) => {
                      return (
                        <div
                          className="sub-ben"
                          style={{
                            marginBottom:
                              index === subBenefit.length - 1 ? 0 : "",
                          }}
                        >
                          <img src={checkmark} alt="" />
                          <p>{item}</p>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {pop && (
        <PopModal
          btnText={"Back to subscription"}
          onclick={() => {
            setPop(false);
            window.location.href = "/userSupscription";
          }}
          popTextContent={
            <div>
              <p className="pop-text1">Plan re-activated</p>
              <p className="pop-text2">
                Your current plan is active till <span>June 12, 2024</span> You
                will be billed on this date for your re-activated subscription.
              </p>
            </div>
          }
        />
      )}
      <Logout />
    </section>
  );
}
