import React, { useEffect } from "react";
import "../css/style.css";
import FadeInSection from "../components/FadeIn";
import { Link } from "react-router-dom";

export default function AboutScreen() {
  useEffect(() => {
    // Scroll to the top when the component mounts
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="black-bg">
      <section className="about-sec abt-section">
        <div className="abt-nav">
          <Link to="/">
            <svg
              width="40"
              height="40"
              viewBox="0 0 40 40"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M13.3333 31.6667C13.3333 30.4301 12.1117 28.5834 10.875 27.0334C9.285 25.0334 7.385 23.2884 5.20667 21.9567C3.57334 20.9584 1.59333 20.0001 9.53674e-07 20.0001M9.53674e-07 20.0001C1.59333 20.0001 3.575 19.0417 5.20667 18.0434C7.385 16.7101 9.285 14.9651 10.875 12.9684C12.1117 11.4167 13.3333 9.56675 13.3333 8.33341M9.53674e-07 20.0001L40 20.0001"
                stroke="white"
                stroke-width="2"
              />
            </svg>
          </Link>
        </div>
        <div style={{ width: "100%", height: 168 }}></div>
        <div className="abt-text-section">
          <div className="abt-text-wrap">
            <FadeInSection>
              <p className="abt-g-text">About Us</p>
            </FadeInSection>
            <FadeInSection>
              <p
                className="mt-text2 redHat-regular abt-p-text"
                style={{ textAlign: "left" }}
              >
                We are a team of forward-thinking innovators dedicated to
                empowering project founders and investors to benefit from the
                advantages of blockchain technology.
              </p>

              <p
                className="mt-text2 redHat-regular abt-p-text"
                style={{ textAlign: "left" }}
              >
                We aim to bridge the gap between blockchain innovation and legal
                compliance, building an ecosystem that promotes growth,
                collaboration, transparency, and success. We're committed to
                delivering expert insights, well-researched reports, and a
                platform that supports growth.
              </p>

              <p
                className="mt-text2 redHat-regular abt-p-text"
                style={{ textAlign: "left" }}
              >
               With Trendx, you can trust that you're in good hands. Our team of experts is dedicated to helping you achieve your goals, every step of the way.
              </p>
            </FadeInSection>
          </div>
        </div>
      </section>
    </div>
  );
}
