import { RequestHandler } from "./Request";

const request = new RequestHandler();
export function ContactUs(body: object): Promise<any> {
  return new Promise((resolve, reject) => {
    request.post("user/contact-us", body, "", [
      (data: any) => {
        resolve(data);
      },
    ]);
  });
}
export function Login(body: object): Promise<any> {
  return new Promise((resolve, reject) => {
    request.post("bloguser/login", body, "", [
      (data: any) => {
        resolve(data);
      },
    ]);
  });
}

export function CreateUser(body: object): Promise<any> {
  return new Promise((resolve, reject) => {
    request.post("bloguser/register", body, "", [
      (data: any) => {
        resolve(data);
      },
    ]);
  });
}

export function SendEmailVerify(body: object): Promise<any> {
  return new Promise((resolve, reject) => {
    request.post("bloguser/send-verify-email", body, "", [
      (data: any) => {
        resolve(data);
      },
    ]);
  });
}

export function ResendOtp(): Promise<any> {
  return new Promise((resolve, reject) => {
    request.post("bloguser/resendotp", {}, "", [
      (data: any) => {
        resolve(data);
      },
    ]);
  });
}

export function VerifyEmail(body: object): Promise<any> {
  return new Promise((resolve, reject) => {
    request.post("bloguser/verify-email", body, "", [
      (data: any) => {
        resolve(data);
      },
    ]);
  });
}
export function GetResetPasswordOTP(body: object): Promise<any> {
  return new Promise((resolve, reject) => {
    request.post("bloguser/getResetPasswordOtp", body, "", [
      (data: any) => {
        resolve(data);
      },
    ]);
  });
}
export function ResetPassword(body: object): Promise<any> {
  return new Promise((resolve, reject) => {
    request.post("bloguser/resetPassword", body, "", [
      (data: any) => {
        resolve(data);
      },
    ]);
  });
}
