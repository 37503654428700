import { json } from "react-router-dom";
import { RequestHandler } from "./Request";
import Cookies from "js-cookie";
const request = new RequestHandler();

export function GetUserDetails(): Promise<any> {
  return new Promise((resolve, reject) => {
    let token = Cookies.get("webCook");
    if (token) {
      token = token.replace(/^"|"$/g, "");
      request.get("bloguser/userdetails", token, [
        (data: any) => {
          resolve(data);
        },
      ]);
    } else {
      console.log("null");
    }
  });
}

export function UpdateUser(body: object): Promise<any> {
  return new Promise((resolve, reject) => {
    let token = Cookies.get("webCook");
    if (token) {
      token = token.replaceAll(/^"|"$/g, "");
      request.put("bloguser/updateuser", body, token, [
        (data: any) => {
          resolve(data);
        },
      ]);
    } else {
      console.log("null");
    }
  });
}

export function GetSubscriptionPlan(): Promise<any> {
  return new Promise((resolve, reject) => {
    let token = Cookies.get("webCook");
    if (token) {
      token = token.replaceAll(/^"|"$/g, "");
      request.get("subcription/get-current-plan",  token, [
        (data: any) => {
          resolve(data);
        },
      ]);
    } else {
      console.log("null");
    }
  });
}

export function ChangePassword(body: object): Promise<any> {
  return new Promise((resolve, reject) => {
    let token = Cookies.get("webCook");
    if (token) {
      token = token.replaceAll(/^"|"$/g, "");
      request.put("bloguser/changepassword", body, token, [
        (data: any) => {
          resolve(data);
        },
      ]);
    } else {
      console.log("null");
    }
  });
}

export function Subscribe(body: object): Promise<any> {
  return new Promise((resolve, reject) => {
    let token = Cookies.get("webCook");
    if (token) {
      token = token.replaceAll(/^"|"$/g, "");
      request.post("subcription/subcribe", body, token, [
        (data: any) => {
          resolve(data);
        },
      ]);
    } else {
      console.log("null");
    }
  });
}
export function ChangeSubscription(body: object): Promise<any> {
  return new Promise((resolve, reject) => {
    let token = Cookies.get("webCook");
    if (token) {
      token = token.replaceAll(/^"|"$/g, "");
      request.post("subcription/change-plan", body, token, [
        (data: any) => {
          resolve(data);
        },
      ]);
    } else {
      console.log("null");
    }
  });
}


export function AddCard(body: object): Promise<any> {
  return new Promise((resolve, reject) => {
    let token = Cookies.get("webCook");
    if (token) {
      token = token.replaceAll(/^"|"$/g, "");
      request.put("bloguser/addcard", body, token, [
        (data: any) => {
          resolve(data);
        },
      ]);
    } else {
      console.log("null");
    }
  });
}
