import React, { useState, useEffect, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import "../css/style.css";
import Input1 from "../components/Input1";
import eye from "../assests/images/eye.svg";
import eyeOff from "../assests/images/eyeOff.svg";
import checkBox from "../assests/images/checkbox.svg";
import checkBoxActive from "../assests/images/checkbox-active.svg";
import Toast, { handleToast } from "../components/Toast";
import { CreateUser } from "../RequestHandlers/Authentication";
import Loader from "../components/Loader";
import { CredentailsContext } from "../RequestHandlers/CredentailsContext";
import Cookies from "js-cookie";

export default function RegisterScreen() {
  const [fullname, setFullname] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errors, setErrors] = useState({});
  const [eErrors, setEErrors] = useState({});
  const [pErrors, setPErrors] = useState({});
  const [cpErrors, setCpErrors] = useState({});
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [confirmpwdVisible, setConfirmpwdVisible] = useState(false);
  const [isUppercase, setIsUppercase] = useState(false);
  const [is8Characters, setIs8Characters] = useState(false);
  const [isHasNumber, setIsHasNumber] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const [loader, setLoader] = useState(false);
  const { storedCredentails, setStoredCredentails } =
    useContext(CredentailsContext);

  const navigate = useNavigate();

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const toggleCpwdVisible = () => {
    setConfirmpwdVisible(!confirmpwdVisible);
  };

  const uppercaseRegex = /[A-Z]/;
  const numberRegex = /\d/;
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const validateName = () => {
    const newErrors = {};
    if (fullname.length === 0) {
      // @ts-ignore
      newErrors.fullname = "Fullname is required";
    } else if (fullname.length < 4) {
      // @ts-ignore
      newErrors.fullname = "Name is too short";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const validateEmail = () => {
    const newErrors = {};
    if (!email) {
      // @ts-ignore
      newErrors.email = "Email is required";
    } else if (!emailRegex.test(email)) {
      // @ts-ignore
      newErrors.email = "Email is invalid";
    }
    setEErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const validatePassword = (newPassword: any) => {
    const newErrors = {};
    if (!newPassword) {
      // @ts-ignore
      newErrors.password = "Password is required";
      setIs8Characters(false);
      setIsUppercase(false);
      setIsHasNumber(false);
    } else if (newPassword.length < 8) {
      // @ts-ignore
      newErrors.password = "Password should have at least 8 characters";
      setIs8Characters(false);
    } else {
      setIs8Characters(true);
    }

    if (!uppercaseRegex.test(password)) {
      // @ts-ignore
      newErrors.password = "Password must include an uppercase letter";
      setIsUppercase(false);
    } else {
      setIsUppercase(true);
    }

    if (!numberRegex.test(password)) {
      // @ts-ignore
      newErrors.password = "Password must have at least 1 number";
      setIsHasNumber(false);
    } else {
      setIsHasNumber(true);
    }
    setPErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const validateConfirmPassword = (newConfirmPwd: any) => {
    const newErrors = {};
    if (!newConfirmPwd) {
      // @ts-ignore
      newErrors.confirmPassword = "Type in your password again";
    } else if (newConfirmPwd !== password) {
      // @ts-ignore
      newErrors.confirmPassword = "Password does not match";
    }

    setCpErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const validateForm = () => {
    validateName();
    validateEmail();
    validatePassword(password);
    validateConfirmPassword(confirmPassword);
  };

  useEffect(() => {
    // Scroll to the top when the component mounts
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    if (password === "") {
    } else {
      validatePassword(password);
    }
  }, [password]);

  useEffect(() => {
    if (confirmPassword == "") {
    } else {
      validateConfirmPassword(confirmPassword);
    }
  }, [confirmPassword]);
  const persistLogin = (credentials: any) => {
    try {
      Cookies.set("webCook", JSON.stringify(credentials), { expires: 7 });
      // @ts-ignore
      setStoredCredentails(credentials);
      setTimeout(() => {
        navigate("/otpScreen", { state: { email } });
      }, 3000);
    } catch (err) {
      console.error("Error storing credentials:", err);
    }
  };
  const register = async () => {
    setLoader(true);
    try {
      const body = {
        name: fullname,
        email: email,
        password: password,
        confirmpassword: confirmPassword,
      };

      const createUser = await CreateUser(body);
      console.log(createUser);
      if (createUser.user.email) {
        setLoader(false);
        persistLogin(createUser.token);
      } else {
        setLoader(false);
        handleToast(createUser.message, "error");
      }
    } catch (error) {
      // handleToast(error, "error");
      console.log(error);
    }
  };
  const handleFormSubmit = (e: any) => {
    e.preventDefault();
    validateForm();

    if (
      validateName() &&
      validateEmail() &&
      validatePassword(password) &&
      validateConfirmPassword(confirmPassword)
    ) {
      console.log("Form submitted successfully");
      register();
      // handleToast("succesful", "error");
      // Proceed with form submission logic
      // navigate('/otpScreen')
    }
  };

  return (
    <section className="login-section register-section">
      <Loader loader={loader} />
      <div className="login-nav">
        <Link to="/">
          <svg
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M13.3333 31.6667C13.3333 30.4301 12.1117 28.5834 10.875 27.0334C9.285 25.0334 7.385 23.2884 5.20667 21.9567C3.57334 20.9584 1.59333 20.0001 9.53674e-07 20.0001M9.53674e-07 20.0001C1.59333 20.0001 3.575 19.0417 5.20667 18.0434C7.385 16.7101 9.285 14.9651 10.875 12.9684C12.1117 11.4167 13.3333 9.56675 13.3333 8.33341M9.53674e-07 20.0001L40 20.0001"
              stroke="white"
              strokeWidth="2"
            />
          </svg>
        </Link>
      </div>
      <div className="login-topper"></div>
      <div className="login-body">
        <div className="login-wrap">
          <p className="l-head-text">Create an account</p>
          <p className="l-body-text">
            To subscribe to our premium research reports, you'll need to create
            an account. By creating an account, you can save reports and manage
            your subscription.
          </p>
          <form onSubmit={handleFormSubmit}>
            <div className="input-wrap1">
              <Input1
                label="Full name"
                type={"text"}
                value={fullname}
                onChange={(e: any) => {
                  setFullname(e.target.value);
                  validateName();
                }}
                // @ts-ignore
                error={errors.fullname}
              />
            </div>
            <div className="input-wrap1">
              <Input1
                label="Email address"
                type={"text"}
                value={email}
                onChange={(e: any) => {
                  setEmail(e.target.value);
                  validateEmail();
                }}
                // @ts-ignore
                error={eErrors.email}
              />
            </div>
            <div
              className="input-wrap1"
              style={{ display: "flex", flexDirection: "column" }}
            >
              <Input1
                label="Create password"
                type={passwordVisible ? "text" : "password"}
                value={password}
                onChange={(e: any) => {
                  setPassword(e.target.value);
                  validatePassword(password);
                }}
                // @ts-ignore
                error={pErrors.password}
                rightIcon={
                  <div
                    style={{
                      width: "10%",
                      height: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-end",
                    }}
                  >
                    <button
                      style={{
                        background: "none",
                        border: "none",
                        cursor: "pointer",
                      }}
                      onClick={(e) => {
                        e.preventDefault();
                        togglePasswordVisibility();
                      }}
                    >
                      <img
                        src={passwordVisible ? eye : eyeOff}
                        alt=""
                        className="eye-icon"
                      />
                    </button>
                  </div>
                }
              />
              <div className="check-cont">
                <div className="check-content">
                  <img src={is8Characters ? checkBoxActive : checkBox} alt="" />
                  <p>8 characters minimum</p>
                </div>
                <div className="check-content">
                  <img src={isUppercase ? checkBoxActive : checkBox} alt="" />
                  <p>1 UPPERCASE character</p>
                </div>
                <div className="check-content">
                  <img src={isHasNumber ? checkBoxActive : checkBox} alt="" />
                  <p>1 number</p>
                </div>
              </div>
            </div>
            <div className="input-wrap2">
              <Input1
                label="Confirm password"
                type={confirmpwdVisible ? "text" : "password"}
                value={confirmPassword}
                onChange={(e: any) => {
                  setConfirmPassword(e.target.value);
                  validateConfirmPassword(confirmPassword);
                }}
                // @ts-ignore
                error={cpErrors.confirmPassword}
                rightIcon={
                  <div
                    style={{
                      width: "10%",
                      height: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-end",
                    }}
                  >
                    <button
                      style={{
                        background: "none",
                        border: "none",
                        cursor: "pointer",
                      }}
                      onClick={(e) => {
                        e.preventDefault();
                        toggleCpwdVisible();
                      }}
                    >
                      <img
                        src={confirmpwdVisible ? eye : eyeOff}
                        alt=""
                        className="eye-icon"
                      />
                    </button>
                  </div>
                }
              />
            </div>
            <button
              type="submit"
              className="signIn-btn"
              style={{ marginBottom: 84 }}
            >
              Continue
            </button>
          </form>
        </div>
      </div>
      <Toast />
    </section>
  );
}
