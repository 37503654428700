import React from "react";
import "../css/style.css";
import { Link } from "react-router-dom";
import googlePlay from "../assests/images/google-play.svg";

export default function PlayStoreBtn() {
  return (
    <Link
      target="_blank"
      to={"https://play.google.com/store/apps/details?id=com.trendx.app"}
      style={{ textDecoration: "none" }}
    >
      <div className="store-btn">
        <img src={googlePlay} alt="" />
        <p className="redHat-regular">Google Play</p>
      </div>
    </Link>
  );
}
