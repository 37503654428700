import React, { useState, useEffect, useRef } from "react";
import "../css/style.css";
import SideBar from "../components/SideBar";
import ProfileNav from "../components/ProfileNav";
import postImg1 from "../assests/images/postImg1.svg";
import postImg2 from "../assests/images/postImg2.svg";
import postImg3 from "../assests/images/postImg3.svg";
import { Link, useLocation, useNavigate } from "react-router-dom";
import FadeInSection from "../components/FadeIn";
import paperClip from "../assests/images/papperclip.svg";
import emptyDoc from "../assests/images/empty-doc.svg";
import Logout, { handleLogout } from "../components/Logout";
import defaultAvatar from "../assests/images/default-avatar.svg";
import Input1 from "../components/Input1";
import Btn from "../components/Btn";
import { useMediaQuery } from "react-responsive";
import { GetUserDetails, UpdateUser } from "../RequestHandlers/User";
import Toast, { handleToast } from "../components/Toast";

export default function ProfileScreen() {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const location = useLocation();
  const [userInfo, setUserInfo] = useState([]);
  const [avatar, setAvatar] = useState(null);
  const [name, setName] = useState("...");
  const [email, setEmail] = useState("...");
  const fromSupport = location.state?.fromBlog || false;
  const navigate = useNavigate();
  const getUserDetails = async () => {
    try {
      const userDetails = await GetUserDetails();
      setUserInfo(userDetails);
      setAvatar(userDetails.avatar);
      setName(userDetails.name);
      setEmail(userDetails.email);
    } catch (error) {
      console.log(error);
      handleToast("check your internet connection", "error");
    }
  };
  const fileInputRef = useRef(null);

  const handleButtonClick = () => {
    // @ts-ignore
    fileInputRef.current.click();
  };
  const editInfo = async (body: object) => {
    const handle = await UpdateUser(body);
    if (handle.name) {
      handleToast("Name updated Succesfully");
    } else {
      handleToast("An Error Occurred", "error");
    }

    // setMoney(formatter.format(userdetails.balance));
  };

  const handleSaveChanges = () => {
    editInfo({ name });
  };
  const changeProfile = async (img: any) => {
    const handleRequest = await UpdateUser({
      avatar: img,
    });

    if (handleRequest.avatar) {
      handleToast("Updated Profile Picture Successfully");
    } else {
      handleToast("Error with Updating Detail", "error");
    }
  };

  const handleFileChange = (event: any) => {
    const selectedImg = event.target.files[0];
    if (selectedImg) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64String = reader.result;
        // @ts-ignore
        setAvatar(base64String);
        changeProfile(base64String);
      };
      reader.readAsDataURL(selectedImg);
    }
  };
  useEffect(() => {
    // Scroll to the top when the component mounts
    window.scrollTo(0, 0);
    getUserDetails();
  }, []);

  return (
    <section className="dashboard-layout">
      <div className="left-layout">
        <SideBar logoutClick={() => handleLogout()} fromBlog={fromSupport} />
      </div>
      <div className="left-spacer"></div>
      <div className="body-layout" style={{ height: "auto" }}>
        <div className="profile-nav-cont">
          {isMobile ? (
            <>
              <div className="sub-profile-nav">
                {" "}
                <Link
                  to=" "
                  onClick={(e) => {
                    e.preventDefault();
                    navigate(-1);
                  }}
                  className="blogArrow-back"
                  style={{ marginTop: 0, paddingTop: 0 }}
                >
                  <svg
                    width="40"
                    height="40"
                    viewBox="0 0 40 40"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M13.3333 31.6667C13.3333 30.4301 12.1117 28.5834 10.875 27.0334C9.285 25.0334 7.385 23.2884 5.20667 21.9567C3.57334 20.9584 1.59333 20.0001 9.53674e-07 20.0001M9.53674e-07 20.0001C1.59333 20.0001 3.575 19.0417 5.20667 18.0434C7.385 16.7101 9.285 14.9651 10.875 12.9684C12.1117 11.4167 13.3333 9.56675 13.3333 8.33341M9.53674e-07 20.0001L40 20.0001"
                      stroke="white"
                      stroke-width="2"
                    />
                  </svg>
                </Link>
              </div>
            </>
          ) : (
            <ProfileNav />
          )}
        </div>
        {/* <div className="profile-nav-topper"></div> */}
        <div className="main-body ppr-sec">
          <p className="pro-fp">Profile details</p>

          <div className="ppr-img-frame">
            <img
              // @ts-ignore
              src={avatar === null ? defaultAvatar : avatar}
              alt=""
            />
            <div className="up-wrap">
              <p>Image must be - 10MB max file size</p>
              <div className="up-btn-wrap">
                <input
                  type="file"
                  ref={fileInputRef}
                  style={{ display: "none" }}
                  onChange={handleFileChange}
                  accept="image/*" // Restrict to image files
                />
                <button className="up-btn" onClick={handleButtonClick}>
                  <svg
                    width="21"
                    height="20"
                    viewBox="0 0 21 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M16.9844 2.97976C16.6562 2.64881 16.2111 2.46289 15.7469 2.46289L5.24694 2.46289C4.78281 2.46289 4.33769 2.64881 4.0095 2.97976M10.496 17.5352L10.496 7.53516M10.496 7.53516L6.49596 11.3561M10.496 7.53516L14.496 11.3561"
                      stroke="white"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  Upload image
                </button>
                {/* <button className="up-btn">
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M6 18H14C15.1046 18 16 17.1046 16 16V6H17C17.5523 6 18 5.55228 18 5C18 4.44772 17.5523 4 17 4H14.3438L13.5345 2.52019C13.3591 2.19946 13.0227 2 12.6571 2H7.47433C7.04217 2 6.65964 2.27828 6.48767 2.67475C6.31262 3.0783 6.05752 3.59052 5.74549 4H3C2.44772 4 2 4.44772 2 5C2 5.55228 2.44772 6 3 6H4V16C4 17.1046 4.89543 18 6 18ZM8 8C8.55228 8 9 8.44772 9 9V14C9 14.5523 8.55228 15 8 15C7.44772 15 7 14.5523 7 14V9C7 8.44772 7.44772 8 8 8ZM12 8C12.5523 8 13 8.44772 13 9V14C13 14.5523 12.5523 15 12 15C11.4477 15 11 14.5523 11 14V9C11 8.44772 11.4477 8 12 8Z"
                      fill="white"
                      fill-opacity="0.3"
                    />
                  </svg>
                  Delete image
                </button> */}
              </div>
            </div>
          </div>

          <div className="ppr-form">
            <div className="input-wrap1 p-input-wrap1" style={{ marginTop: 0 }}>
              <Input1
                label="Full name"
                labelStyle={{ fontWeight: "400" }}
                value={name}
                onChange={(e: any) => setName(e.target.value)}
              />
            </div>
            <div className="input-wrap1 p-input-wrap1" style={{ marginTop: 0 }}>
              <Input1
                label="Email address"
                labelStyle={{ fontWeight: "400" }}
                value={email}
                inputStyle={{ color: "rgba(255, 255, 255, 0.4)" }}
              />
            </div>
            <div className="input-wrap1 p-input-wrap1" style={{ marginTop: 0 }}>
              <Input1
                label="Password"
                placeholder="Password"
                type={"password"}
                labelStyle={{ fontWeight: "400" }}
                value="Password123#"
              />

              <div className="cp-cont">
                <button
                  className="cp"
                  onClick={() =>
                    navigate("/changePassword", { state: { fromBlog: true } })
                  }
                >
                  Change password
                </button>
              </div>
            </div>

            <Btn
              specialBtnClass="cp-btn"
              text="Save changes"
              onclick={handleSaveChanges}
            />
          </div>
        </div>
      </div>
      <Logout />
      <Toast />
    </section>
  );
}
