import React, { useEffect, useState } from "react";
import "../css/style.css";
import FadeInSection from "../components/FadeIn";
import { Link } from "react-router-dom";
import Input from "../components/Input";
import Textarea from "../components/Textarea";
import Btn from "../components/Btn";
import Input1 from "../components/Input1";
import Loader from "../components/Loader";
import { ContactUs } from "../RequestHandlers/Authentication";
import Toast, { handleToast } from "../components/Toast";

export default function ContactUsScreen() {
  const [fullname, setFullname] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [errors, setErrors] = useState({});
  const [eErrors, setEErrors] = useState({});
  const [pErrors, setPErrors] = useState({});
  const [mErrors, setMErrors] = useState({});
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    // Scroll to the top when the component mounts
    window.scrollTo(0, 0);
  }, []);

  const numberRegex = /\d/;
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const validateName = () => {
    const newErrors = {};
    if (fullname.length === 0) {
      // @ts-ignore
      newErrors.fullname = "Fullname is required";
    } else if (fullname.length < 4) {
      // @ts-ignore
      newErrors.fullname = "Name is too short";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const validateEmail = () => {
    const newErrors = {};
    if (!email) {
      // @ts-ignore
      newErrors.email = "Email is required";
    } else if (!emailRegex.test(email)) {
      // @ts-ignore
      newErrors.email = "Email is invalid";
    }
    setEErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const validatePhone = () => {
    const newErrors = {};
    if (!phone) {
      // @ts-ignore
      newErrors.phone = "Phone number is required";
    } else if (fullname.length < 4) {
      // @ts-ignore
      newErrors.phone = "Phone number is too short";
    } else if (!numberRegex.test(phone)) {
      // @ts-ignore
      newErrors.phone = "Number should not have any alphabet or symbol";
    }
    setPErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const validateMessage = () => {
    const newErrors = {};
    if (!message) {
      // @ts-ignore
      newErrors.phone = "Type in something";
    }
    setMErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };
  const handleChange = (e: any) => {
    setMessage(e.target.value);
    validateMessage();
  };

  const contactUs = async () => {
    setLoader(true);
    try {
      const body = {
        name: fullname,
        email: email,
        phone: phone, // this key is optional
        message: message,
      };

      const sendMessage = await ContactUs(body);
      if (sendMessage.status === true) {
        setLoader(false);
        handleToast(sendMessage.message, "success");
        setFullname("")
        setEmail("")
        setPhone("")
        setMessage("")
      } else {
        setLoader(false);
        handleToast(sendMessage.message, "error");
        setFullname("")
        setEmail("")
        setPhone("")
        setMessage("")
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleFormSubmit = (e: any) => {
    e.preventDefault();
    validateName();
    validateEmail();
    validatePhone();
    validateMessage();

    if (
      validateName() &&
      validateEmail() &&
      validatePhone() &&
      validateMessage()
    ) {
      console.log("Form submitted successfully");
      contactUs();
      // handleToast("succesful", "error");
      // Proceed with form submission logic
      // navigate('/otpScreen')
    }
  };

  return (
    <div className="black-bg">
      <Loader loader={loader} />
      <section className="about-sec ctu-section">
        <div className="abt-nav contact-nav">
          <Link to="/">
            <svg
              width="40"
              height="40"
              viewBox="0 0 40 40"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M13.3333 31.6667C13.3333 30.4301 12.1117 28.5834 10.875 27.0334C9.285 25.0334 7.385 23.2884 5.20667 21.9567C3.57334 20.9584 1.59333 20.0001 9.53674e-07 20.0001M9.53674e-07 20.0001C1.59333 20.0001 3.575 19.0417 5.20667 18.0434C7.385 16.7101 9.285 14.9651 10.875 12.9684C12.1117 11.4167 13.3333 9.56675 13.3333 8.33341M9.53674e-07 20.0001L40 20.0001"
                stroke="white"
                stroke-width="2"
              />
            </svg>
          </Link>
        </div>
        <div className="top-space" style={{ width: "100%", height: 124 }}></div>
        <div className="abt-text-section ctu-content">
          <div
            className="abt-text-wrap ctu-text-wrap"
            style={{ marginBottom: 0, maxWidth: "100vw" }}
          >
            <FadeInSection>
              <p className="abt-g-text ctu-g-text">Contact Us</p>
            </FadeInSection>
            <div className="input-wrap1">
              <Input1
                label="Full name"
                type={"text"}
                value={fullname}
                onChange={(e: any) => {
                  setFullname(e.target.value);
                  validateName();
                }}
                // @ts-ignore
                error={errors.fullname}
              />
            </div>
            <div className="input-wrap">
              <Input1
                label="Email address"
                type={"text"}
                value={email}
                onChange={(e: any) => {
                  setEmail(e.target.value);
                  validateEmail();
                }}
                // @ts-ignore
                error={eErrors.email}
              />
            </div>
            <div className="input-wrap">
              <Input1
                label="Phone Number"
                type={"text"}
                value={phone}
                onChange={(e: any) => {
                  setPhone(e.target.value);
                  validatePhone();
                }}
                // @ts-ignore
                error={pErrors.phone}
              />
            </div>
            <div className="input-wrap">
              <Textarea
                label="Message"
                value={message}
                onChange={handleChange}
                // @ts-ignore
                error={mErrors.message}
                placeholder="Type a message"
              />
            </div>
            <div style={{ marginBottom: 123 }} className="send-btn-wrap">
              <Btn type="long" text="Send message" onclick={handleFormSubmit} />
            </div>
          </div>
        </div>
      </section>
      <Toast />
    </div>
  );
}
