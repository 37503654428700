import React, { useEffect, useState } from "react";
import "../css/style.css";
import ProfileNav from "../components/ProfileNav";
import MobileProfileNav from "../components/MobileProfileNav";
import postImg1 from "../assests/images/postImg1.svg";
import postImg2 from "../assests/images/postImg2.svg";
import postImg3 from "../assests/images/postImg3.svg";
import megaSearch from "../assests/images/mega-search.svg";
import { Link, useNavigate } from "react-router-dom";
import FadeInSection from "../components/FadeIn";
import paperClip from "../assests/images/papperclip.svg";
import { SearchBlog } from "../RequestHandlers/Blogs";
import { formatDate } from "../components/FormatDate";
import Loader from "../components/Loader";
import Logout, { handleLogout } from "../components/Logout";

export default function MobileSearchScreen() {
  const [searchValue, setSearchValue] = useState("");
  const navigate = useNavigate();
  // @ts-ignore
  const [searchResult, setSearchResult] = useState([]);
  const [loader, setLoader] = useState(false);
  // @ts-ignore
  const truncateText = (text, maxLength) => {
    let charCount = 0;
    let truncatedText = "";

    for (let char of text) {
      if (char !== " ") {
        charCount++;
      }
      if (charCount <= maxLength) {
        truncatedText += char;
      } else {
        truncatedText += "...";
        break;
      }
    }

    return truncatedText;
  };

  const searchBlog = async () => {
    if (searchValue.trim() === "") {
      setSearchResult([]);
      setLoader(false);
      return;
    }

    setLoader(true);
    try {
      const searchItem = await SearchBlog(false, searchValue);
      setSearchResult(searchItem.items);
    } catch (error) {
      console.log(error);
    } finally {
      setLoader(false);
    }
  };

  const handleInputChange = (e: any) => {
    const value = e.target.value;
    setSearchValue(value);
    if (value.trim() === "") {
      setSearchResult([]);
    }
  };
  // @ts-ignore
  const handleReadMoreClick = (slug, category) => {
    navigate("/blogPostScreen", { state: { slug, category } }); // Pass the blog item as state
  };

  useEffect(() => {
    searchBlog();
  }, [searchValue]);

  return (
    <>
      <div className="ms-section">
        <Logout />
        <div className="ms-nav">
          <MobileProfileNav
            logoutClick={handleLogout}
            userName={false}
            navStyle={{ justifyContent: "space-between" }}
            notiStyle={{ position: "relative" }}
            searchInput={
              <>
                <div className="s-inputCont">
                  <button>
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M11 19C15.4183 19 19 15.4183 19 11C19 6.58172 15.4183 3 11 3C6.58172 3 3 6.58172 3 11C3 15.4183 6.58172 19 11 19Z"
                        stroke="#B6B6B6"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M20.9992 21.0002L16.6992 16.7002"
                        stroke="#B6B6B6"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </button>
                  <input
                    type="text"
                    placeholder="Search blog"
                    value={searchValue}
                    onChange={handleInputChange}
                  />
                </div>
              </>
            }
          />
        </div>
        <div className="ms-body" style={{ paddingTop: 90 }}>
          {/* <Loader loader={loader} loaderStyle={{ top: 70 }} /> */}
          {searchValue.length === 0 ? (
            <>
              {/* <div className="null-search">
                <img src={megaSearch} alt="" />
                <h4>Oops</h4>
                <p>
                  Looks like there are no results for this search, try a
                  different search?
                </p>
              </div> */}
            </>
          ) : searchResult.length === 0 ? (
            <>
              <div className="null-search" style={{ height: "100vh" }}>
                <img src={megaSearch} alt="" />
                <h4>No results</h4>
                <p>
                  Looks like there are no results for this search, try a
                  different search?
                </p>
              </div>
            </>
          ) : (
            <>
              {/* @ts-ignore */}
              {searchResult.map((item, index) => {
                // @ts-ignore
                const parser = new DOMParser();
                // @ts-ignore
                const doc = parser.parseFromString(item.body, "text/html");
                let firstParagraph = doc.querySelector("p");
                const firstImag = doc.querySelector("img");

                // Check if the first paragraph is empty or contains only whitespace
                if (
                  !firstParagraph ||
                  // @ts-ignore
                  firstParagraph.textContent.trim() === ""
                ) {
                  const paragraphs = doc.querySelectorAll("p");
                  if (paragraphs.length > 1) {
                    firstParagraph = paragraphs[1];
                  }
                }

                return (
                  <FadeInSection>
                    <div
                      className="blog-content-card db-content-card s-content"
                      style={{ marginTop: 60 }}
                      onClick={(e) => {
                        e.preventDefault();
                        handleReadMoreClick(
                          // @ts-ignore
                          item.slug,
                          // @ts-ignore
                          item.category.name
                        );
                      }}
                    >
                      <div className="blog-card-header db-header">
                        <div className="bch-wrap bch-wrap1">
                          <img src={paperClip} alt="" />
                          {/* @ts-ignore */}
                          <p className="bch-p bch-p1">{item.category.name}</p>
                        </div>
                        {/* @ts-ignore */}
                        <p className="db-date">{formatDate(item.created_at)}</p>
                      </div>
                      <div className="line-seperator db-seperator"></div>
                      {/* @ts-ignore */}
                      <p className="blog-title blog-title1">{item.title}</p>
                      <p className="blog-post-body bp-body1">
                        {firstParagraph
                          ? truncateText(firstParagraph.textContent, 120)
                          : ""}{" "}
                        <span>
                          <Link
                            to={""}
                            onClick={(e) => {
                              e.preventDefault();
                              handleReadMoreClick(
                                // @ts-ignore
                                item.slug,
                                // @ts-ignore
                                item.category.name
                              );
                            }}
                            className="readMore"
                          >
                            Read More
                          </Link>
                        </span>
                      </p>

                      <img
                        // @ts-ignore
                        src={firstImag ? firstImag.src : ""}
                        className="b-postImg"
                        alt=""
                      />
                    </div>
                  </FadeInSection>
                );
              })}
            </>
          )}
        </div>
      </div>
    </>
  );
}
