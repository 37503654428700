import React from "react";
import "../css/style.css";
import { Link } from "react-router-dom";

export default function AppStoreBtn() {
  return (
    <Link to={"https://apps.apple.com/us/app/trendx/id6451397953"} target="_blank" style={{textDecoration: "none"}}>
    <div className="store-btn app-store-btn" >
      <svg
        width="21"
        height="24"
        viewBox="0 0 21 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M17.2956 12.7631C17.3195 10.9104 18.3145 9.15738 19.8929 8.18699C18.8971 6.76488 17.2293 5.86322 15.4941 5.80893C13.6433 5.61467 11.8491 6.91639 10.906 6.91639C9.94465 6.91639 8.49259 5.82822 6.92895 5.86039C4.89083 5.92624 2.99078 7.08501 1.99919 8.86687C-0.132337 12.5573 1.45759 17.9809 3.49942 20.964C4.52099 22.4247 5.71491 24.0564 7.27713 23.9985C8.80586 23.9351 9.37681 23.0237 11.2221 23.0237C13.0503 23.0237 13.586 23.9985 15.1799 23.9617C16.8203 23.9351 17.8538 22.4945 18.8395 21.02C19.5735 19.9792 20.1383 18.8288 20.513 17.6116C18.5853 16.7962 17.2979 14.8562 17.2956 12.7631Z"
          fill="white"
        />
        <path
          d="M14.285 3.84713C15.1794 2.77343 15.62 1.39335 15.5133 0C14.1469 0.143521 12.8846 0.796601 11.9781 1.82911C11.0917 2.83793 10.6304 4.19372 10.7176 5.53382C12.0846 5.54789 13.4284 4.91252 14.285 3.84713Z"
          fill="white"
        />
      </svg>
      <p className="redHat-regular">App Store</p>
    </div>
    </Link>
  );
}
