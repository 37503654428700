import React, { useState, useEffect } from "react";
import "../css/style.css";
import SideBar from "../components/SideBar";
import ProfileNav from "../components/ProfileNav";
import postImg1 from "../assests/images/postImg1.svg";
import postImg2 from "../assests/images/postImg2.svg";
import postImg3 from "../assests/images/postImg3.svg";
import { Link, useLocation, useNavigate } from "react-router-dom";
import FadeInSection from "../components/FadeIn";
import paperClip from "../assests/images/papperclip.svg";
import emptyDoc from "../assests/images/empty-doc.svg";
import Logout, { handleLogout } from "../components/Logout";
import defaultAvatar from "../assests/images/default-avatar.svg";
import Input1 from "../components/Input1";
import Btn from "../components/Btn";
import eye from "../assests/images/eye.svg";
import eyeOff from "../assests/images/eyeOff.svg";
import checkBox from "../assests/images/checkbox.svg";
import checkBoxActive from "../assests/images/checkbox-active.svg";
import { useMediaQuery } from "react-responsive";
import Loader from "../components/Loader";
import Toast, { handleToast } from "../components/Toast";
import { ChangePassword } from "../RequestHandlers/User";

export default function ChangePasswordScreen() {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const navigate = useNavigate();
  const location = useLocation();
  const fromSupport = location.state?.fromBlog || false;
  const [curPassword, setCurPassword] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [curError, setCurError] = useState({});
  const [pErrors, setPErrors] = useState({});
  const [cpErrors, setCpErrors] = useState({});
  const [cPasswordVisible, setCPasswordVisible] = useState(false);
  const [nPasswordVisible, setNPasswordVisible] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [isUppercase, setIsUppercase] = useState(false);
  const [is8Characters, setIs8Characters] = useState(false);
  const [isHasNumber, setIsHasNumber] = useState(false);
  const [loader, setLoader] = useState(false);
  const uppercaseRegex = /[A-Z]/;
  const numberRegex = /\d/;
  const togglePasswordVisibility = () => {
    setCPasswordVisible(!cPasswordVisible);
  };
  const toggleNPV = () => {
    setNPasswordVisible(!nPasswordVisible);
  };

  const togglePV = () => {
    setPasswordVisible(!passwordVisible);
  };
  const validateCurrentPassword = () => {
    const newErrors = {};
    if (!curPassword) {
      // @ts-ignore
      newErrors.curPassword = "Current password is required";
    }
    setCurError(newErrors);
    return Object.keys(newErrors).length === 0;
  };
  const validatePassword = (newPassword: any) => {
    const newErrors = {};
    if (!newPassword) {
      // @ts-ignore
      newErrors.password = "Password is required";
      setIs8Characters(false);
      setIsUppercase(false);
      setIsHasNumber(false);
    } else if (newPassword.length < 8) {
      // @ts-ignore
      newErrors.password = "Password should have at least 8 characters";
      setIs8Characters(false);
    } else {
      setIs8Characters(true);
    }

    if (!uppercaseRegex.test(password)) {
      // @ts-ignore
      newErrors.password = "Password must include an uppercase letter";
      setIsUppercase(false);
    } else {
      setIsUppercase(true);
    }

    if (!numberRegex.test(password)) {
      // @ts-ignore
      newErrors.password = "Password must have at least 1 number";
      setIsHasNumber(false);
    } else {
      setIsHasNumber(true);
    }
    setPErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const validateConfirmPassword = (newConfirmPwd: any) => {
    const newErrors = {};
    if (!newConfirmPwd) {
      // @ts-ignore
      newErrors.confirmPassword = "Type in your password again";
    } else if (newConfirmPwd !== password) {
      // @ts-ignore
      newErrors.confirmPassword = "Password does not match";
    }

    setCpErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };
  useEffect(() => {
    // Scroll to the top when the component mounts
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    if (password === "") {
    } else {
      validatePassword(password);
    }
  }, [password]);

  useEffect(() => {
    if (confirmPassword == "") {
    } else {
      validateConfirmPassword(confirmPassword);
    }
  }, [confirmPassword]);

  const changePassword = async () => {
    try {
      const body = {
        currentPassword: curPassword,
        newPassword: password,
        confirmPassword: confirmPassword,
      };
      const updatePwd = await ChangePassword(body);
      if (updatePwd.email) {
        setCurPassword("");
        setPassword("");
        setConfirmPassword("");
        setIsUppercase(false)
        setIsHasNumber(false)
        setIs8Characters(false)
        setLoader(false);
        handleToast("password updated successfully", "success");
      } else if (updatePwd.error === "Not Found") {
        setLoader(false);
        handleToast("incurrect password", "error");
      } else {
        setLoader(false);
        handleToast(updatePwd.message, "error");
      }
    } catch (error) {
      handleToast("check your connection and try again", "error");
    }
  };

  const handleFormSubmit = (e: any) => {
    e.preventDefault();
    setLoader(true);
    if (
      validateCurrentPassword() &&
      validatePassword(password) &&
      validateConfirmPassword(confirmPassword)
    ) {
      console.log("Form submitted successfully");
      changePassword();
      // handleToast("succesful", "error");
      // Proceed with form submission logic
      // navigate('/otpScreen')
    }
  };

  return (
    <section className="dashboard-layout">
      <Loader loader={loader} />
      <div className="left-layout">
        <SideBar logoutClick={() => handleLogout()} fromBlog={fromSupport} />
      </div>
      <div className="left-spacer"></div>
      <div className="body-layout" style={{ height: "auto" }}>
        <div className="profile-nav-cont">
          {isMobile ? (
            <>
              <div className="sub-profile-nav">
                {" "}
                <Link
                  to=" "
                  onClick={(e) => {
                    e.preventDefault();
                    navigate(-1);
                  }}
                  className="blogArrow-back"
                  style={{ marginTop: 0, paddingTop: 0 }}
                >
                  <svg
                    width="40"
                    height="40"
                    viewBox="0 0 40 40"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M13.3333 31.6667C13.3333 30.4301 12.1117 28.5834 10.875 27.0334C9.285 25.0334 7.385 23.2884 5.20667 21.9567C3.57334 20.9584 1.59333 20.0001 9.53674e-07 20.0001M9.53674e-07 20.0001C1.59333 20.0001 3.575 19.0417 5.20667 18.0434C7.385 16.7101 9.285 14.9651 10.875 12.9684C12.1117 11.4167 13.3333 9.56675 13.3333 8.33341M9.53674e-07 20.0001L40 20.0001"
                      stroke="white"
                      stroke-width="2"
                    />
                  </svg>
                </Link>
              </div>
            </>
          ) : (
            <ProfileNav />
          )}
        </div>
        {/* <div className="profile-nav-topper"></div> */}
        <div className="main-body ppr-sec">
          <p className="pro-fp chp-fp">Change password</p>
          <div className="ppr-form">
            <div className="input-wrap1" style={{ marginTop: 0 }}>
              <Input1
                label="Current password"
                labelStyle={{ fontWeight: "500" }}
                type={cPasswordVisible ? "text" : "password"}
                value={curPassword}
                // @ts-ignore
                error={curError.curPassword}
                onChange={(e: any) => {
                  setCurPassword(e.target.value);
                  validateCurrentPassword();
                }}
                rightIcon={
                  <div
                    style={{
                      width: "10%",
                      height: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-end",
                    }}
                  >
                    <button
                      style={{
                        background: "none",
                        border: "none",
                        cursor: "pointer",
                      }}
                      onClick={(e) => {
                        e.preventDefault();
                        togglePasswordVisibility();
                      }}
                    >
                      <img
                        src={cPasswordVisible ? eyeOff : eye}
                        alt=""
                        className="eye-icon"
                      />
                    </button>
                  </div>
                }
              />
            </div>
            <div className="input-wrap1" style={{ marginTop: 0 }}>
              <Input1
                label="New password"
                labelStyle={{ fontWeight: "500" }}
                type={nPasswordVisible ? "text" : "password"}
                value={password}
                onChange={(e: any) => setPassword(e.target.value)}
                // @ts-ignore
                error={pErrors.password}
                rightIcon={
                  <div
                    style={{
                      width: "10%",
                      height: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-end",
                    }}
                  >
                    <button
                      style={{
                        background: "none",
                        border: "none",
                        cursor: "pointer",
                      }}
                      onClick={(e) => {
                        e.preventDefault();
                        toggleNPV();
                      }}
                    >
                      <img
                        src={nPasswordVisible ? eyeOff : eye}
                        alt=""
                        className="eye-icon"
                      />
                    </button>
                  </div>
                }
              />
              <div className="check-cont">
                <div className="check-content">
                  <img src={is8Characters ? checkBoxActive : checkBox} alt="" />
                  <p style={{ fontSize: 13 }}>8 characters minimum</p>
                </div>
                <div className="check-content">
                  <img src={isUppercase ? checkBoxActive : checkBox} alt="" />
                  <p style={{ fontSize: 13 }}>1 UPPERCASE character</p>
                </div>
                <div className="check-content">
                  <img src={isHasNumber ? checkBoxActive : checkBox} alt="" />
                  <p style={{ fontSize: 13 }}>1 number</p>
                </div>
              </div>
            </div>
            <div className="input-wrap1" style={{ marginTop: 0 }}>
              <Input1
                label="Confirm password"
                type={passwordVisible ? "text" : "password"}
                value={confirmPassword}
                onChange={(e: any) => setConfirmPassword(e.target.value)}
                // @ts-ignore
                error={cpErrors.confirmPassword}
                rightIcon={
                  <div
                    style={{
                      width: "10%",
                      height: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-end",
                    }}
                  >
                    <button
                      style={{
                        background: "none",
                        border: "none",
                        cursor: "pointer",
                      }}
                      onClick={(e) => {
                        e.preventDefault();
                        togglePV();
                      }}
                    >
                      <img
                        src={passwordVisible ? eyeOff : eye}
                        alt=""
                        className="eye-icon"
                      />
                    </button>
                  </div>
                }
                labelStyle={{ fontWeight: "500" }}
              />
            </div>

            <Btn
              specialBtnClass="cp-btn"
              text="Save changes"
              onclick={handleFormSubmit}
            />
          </div>



          
        </div>

      </div>
      <Logout />
      <Toast />
    </section>
  );
}
