import React, { useEffect } from "react";
import "../css/style.css";
import FadeInSection from "../components/FadeIn";
import { Link } from "react-router-dom";

export default function LegalScreen() {
  useEffect(() => {
    // Scroll to the top when the component mounts
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="black-bg">
      <section className="about-sec aml">
        <div className="abt-nav">
          <Link to="/">
            <svg
              width="40"
              height="40"
              viewBox="0 0 40 40"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M13.3333 31.6667C13.3333 30.4301 12.1117 28.5834 10.875 27.0334C9.285 25.0334 7.385 23.2884 5.20667 21.9567C3.57334 20.9584 1.59333 20.0001 9.53674e-07 20.0001M9.53674e-07 20.0001C1.59333 20.0001 3.575 19.0417 5.20667 18.0434C7.385 16.7101 9.285 14.9651 10.875 12.9684C12.1117 11.4167 13.3333 9.56675 13.3333 8.33341M9.53674e-07 20.0001L40 20.0001"
                stroke="white"
                stroke-width="2"
              />
            </svg>
          </Link>
        </div>
        <div className="top-space" style={{ width: "100%", height: 168 }}></div>
        <div className="abt-text-section">
          <div className="abt-text-wrap" style={{ marginBottom: 430 }}>
            <FadeInSection>
              <p className="abt-g-text aml-text">AML Policy</p>
            </FadeInSection>
            <FadeInSection>
              <p
                className="mt-text2 redHat-semiBold abt-p-text"
                style={{ textAlign: "left" }}
              >
                IMPORTANT INFORMATION ABOUT PROCEDURES FOR OPENING <br />A NEW
                ACCOUNT:
              </p>

              <p
                className="mt-text2 redHat-regular abt-p-text"
                style={{ textAlign: "left" }}
              >
                To help the government fight the funding of terrorism and money
                laundering activities, federal law requires all financial
                institutions to obtain, verify, and record information that
                identifies each person who opens an Account. What this means for
                you: When you open an Account, we will ask for your name,
                address, date of birth, and other information that will allow us
                to identify you. We may also ask to see a copy of your driver’s
                license and/or International Passport.
              </p>
            </FadeInSection>
          </div>
        </div>
      </section>
    </div>
  );
}
