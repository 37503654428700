import React, { useState, useEffect } from "react";
import "../css/style.css";
import SideBar from "../components/SideBar";
import ProfileNav from "../components/ProfileNav";
import postImg1 from "../assests/images/postImg1.svg";
import postImg2 from "../assests/images/postImg2.svg";
import postImg3 from "../assests/images/postImg3.svg";
import { Link } from "react-router-dom";
import FadeInSection from "../components/FadeIn";
import paperClip from "../assests/images/papperclip.svg";
import emptyDoc from "../assests/images/empty-doc.svg";
import Logout, { handleLogout } from "../components/Logout";

export default function DashAboutScreen() {
  useEffect(() => {
    // Scroll to the top when the component mounts
    window.scrollTo(0, 0);
  }, []);
  return (
    <section className="dashboard-layout">
      <div className="left-layout">
        <SideBar logoutClick={() => handleLogout()} />
      </div>
      <div className="left-spacer"></div>
      <div className="body-layout">
        <div className="profile-nav-cont">
          <ProfileNav logoutClick={handleLogout}/>
        </div>
        {/* <div className="profile-nav-topper"></div> */}
        <div className="main-body ad-main-body ad-main-body2">
          <p className="g-text2 bd-text ab-text">About Us</p>
          <div className="ab-text-sec">
            <p>
              We are a team of forward-thinking innovators dedicated to
              empowering project founders and investors to benefit from the
              advantages of blockchain technology.
            </p>
            <p>
              We aim to bridge the gap between blockchain innovation and legal
              compliance, building an ecosystem that promotes growth,
              collaboration, transparency, and success. We're committed to
              delivering expert insights, well-researched reports, and a
              platform that supports growth.
            </p>
            <p>
              With Trendx, you can trust that you're in good hands. Our team of
              experts is dedicated to helping you achieve your goals, every step
              of the way.
            </p>
          </div>
        </div>
      </div>
      <Logout />
    </section>
  );
}
