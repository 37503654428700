import React from "react";
import "../css/style.css";

interface PProps {
  text?: string;
  onclick?: any;
  type?: "long" | "short";
  specialBtnClass?: string;
}

export default function Btn({ text, onclick, type, specialBtnClass }: PProps) {
  return (
    <button style={{cursor: "pointer"}} className={`${specialBtnClass} ${type === "short" ? "btn" : "btn-long"}`} onClick={onclick}>
      {text}
    </button>
  );
}
