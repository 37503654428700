import { RequestHandler } from "./Request";
import Cookies from "js-cookie";

const request = new RequestHandler();

export function GetCurrentPlan(): Promise<any> {
  return new Promise((resolve, reject) => {
    let token = Cookies.get("webCook");
    if (token) {
      token = token.replace(/^"|"$/g, "");
      request.get(`subcription/get-current-plan`, token, [
        (data: any) => {
          resolve(data);
        },
      ]);
    }
  });
}

export function CancelSubscribtion(id: any): Promise<any> {
  return new Promise((resolve, reject) => {
    let token = Cookies.get("webCook");
    if (token) {
      token = token.replaceAll(/^"|"$/g, "");
      request.post(`subcription/cancel-subcription/${id}`, {}, token, [
        (data: any) => {
          resolve(data);
        },
      ]);
    } else {
      console.log("null");
    }
  });
}
