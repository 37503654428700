import { RequestHandler } from "./Request";
import Cookies from "js-cookie";

const request = new RequestHandler();

export function GetUserNotifications(): Promise<any> {
  return new Promise((resolve, reject) => {
    let token = Cookies.get("webCook");
    if (token) {
      token = token.replace(/^"|"$/g, "");
      request.get(`notification/bloguser/get?status=all&type=all`, token, [
        (data: any) => {
          resolve(data);
        },
      ]);
    }
  });
}
