import React from "react";
import "../css/style.css";

interface PProps {
  onclick?: any;
  backgroundColor?: any;
  strokeOpacity?: any;
  disabled?: any;
}

export default function RightBtn({
  onclick,
  backgroundColor,
  strokeOpacity,
  disabled,
}: PProps) {
  return (
    <button
      onClick={onclick}
      style={{ border: "none", background: "none" }}
      disabled={disabled}
    >
      <svg
        width="80"
        height="80"
        viewBox="0 0 80 80"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="flow-btn"
      >
        <rect width="80" height="80" rx="40" fill={backgroundColor} />
        <path
          d="M24.0001 40H29.0001M56.0001 40L44.0001 28M56.0001 40L44.0001 52M56.0001 40H35.0001"
          stroke="white"
          stroke-opacity={strokeOpacity}
          stroke-width="4.28571"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </button>
  );
}
