import React, { useState, useEffect } from "react";
import "../css/style.css";
import SideBar from "../components/SideBar";
import ProfileNav from "../components/ProfileNav";
import postImg1 from "../assests/images/postImg1.svg";
import postImg2 from "../assests/images/postImg2.svg";
import postImg3 from "../assests/images/postImg3.svg";
import { Link, useNavigate } from "react-router-dom";
import whatsapp from "../assests/images/whatsapp.svg";
import qmark from "../assests/images/qmark.svg";
import Logout, { handleLogout } from "../components/Logout";

export default function HelpSupport() {
  const navigate = useNavigate();
  useEffect(() => {
    // Scroll to the top when the component mounts
    window.scrollTo(0, 0);
  }, []);
  return (
    <section className="dashboard-layout">
      <div className="left-layout">
        <SideBar logoutClick={() => handleLogout()} />
      </div>
      <div className="left-spacer"></div>
      <div className="body-layout">
        <div className="profile-nav-cont">
          <ProfileNav logoutClick={() => handleLogout()} />
        </div>
        {/* <div className="profile-nav-topper"></div> */}
        <div className="main-body ad-main-body hs-main-body">
          <p className="g-text2 bd-text" style={{ marginBottom: 10 }}>
            Help/Support
          </p>
          <div className="hs-text-sec">
            <p>
              We’ve put together the most Frequently Asked Questions people have
              about Trendx, our services/products, the FAQs page is a good place
              to start if you have questions. You can also talk to our support
              team on WhatsApp for more help.
            </p>
          </div>
          <div className="hs-modal">
            <div className="hs-modal-items">
              <div className="item-card">
                <img src={whatsapp} alt="" />
              </div>
              <Link
                to={"https://wa.me/+16694994294"}
                target="_blank"
                style={{ textDecoration: "none", color: "#000" }}
              >
                <button className="hs-btn">
                  Talk to our team
                  <svg
                    width="43"
                    height="44"
                    viewBox="0 0 43 44"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect
                      x="0.28125"
                      y="0.808594"
                      width="42.4795"
                      height="42.4795"
                      rx="21.2397"
                      fill="black"
                    />
                    <path
                      d="M13.4297 22.048H15.9582M29.6123 22.048L23.5438 15.9795M29.6123 22.048L23.5438 28.1165M29.6123 22.048H18.9925"
                      stroke="white"
                      stroke-width="1.8964"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </button>
              </Link>
            </div>
            <div className="hs-modal-items">
              <div className="item-card">
                <img src={qmark} alt="" />
              </div>
              <button
                className="hs-btn"
                onClick={() =>
                  navigate("/faqScreen", { state: { fromSupport: true } })
                }
              >
                FAQs
                <svg
                  width="43"
                  height="44"
                  viewBox="0 0 43 44"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    x="0.28125"
                    y="0.808594"
                    width="42.4795"
                    height="42.4795"
                    rx="21.2397"
                    fill="black"
                  />
                  <path
                    d="M13.4297 22.048H15.9582M29.6123 22.048L23.5438 15.9795M29.6123 22.048L23.5438 28.1165M29.6123 22.048H18.9925"
                    stroke="white"
                    stroke-width="1.8964"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
      <Logout />
    </section>
  );
}
