import React, { useEffect } from "react";
import "../css/style.css";
import FadeInSection from "../components/FadeIn";
import { Link } from "react-router-dom";

export default function PrivacyPolicyScreen() {
  useEffect(() => {
    // Scroll to the top when the component mounts
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="black-bg">
      <section className="pp-section">
        <div className="abt-nav">
          <Link to="/">
            <svg
              width="40"
              height="40"
              viewBox="0 0 40 40"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M13.3333 31.6667C13.3333 30.4301 12.1117 28.5834 10.875 27.0334C9.285 25.0334 7.385 23.2884 5.20667 21.9567C3.57334 20.9584 1.59333 20.0001 9.53674e-07 20.0001M9.53674e-07 20.0001C1.59333 20.0001 3.575 19.0417 5.20667 18.0434C7.385 16.7101 9.285 14.9651 10.875 12.9684C12.1117 11.4167 13.3333 9.56675 13.3333 8.33341M9.53674e-07 20.0001L40 20.0001"
                stroke="white"
                stroke-width="2"
              />
            </svg>
          </Link>
        </div>
        <div className="top-space" style={{ width: "100%", height:  168 }}></div>
        <div className="abt-text-section">
          <div className="abt-text-wrap" style={{marginBottom: 80}}>
            <FadeInSection>
              <p className="abt-g-text policy-g-text">Privacy Policy</p>
            </FadeInSection>
            <FadeInSection>
              <p
                className="mt-text2 redHat-regular abt-p-text"
                style={{ textAlign: "left", color: "rgba(255, 255, 255, 0.8)" }}
              >
                This Privacy Policy (the "Policy") explains how Trendx.app
                ("Trendx", "we", "us" or "our") collects, uses, and shares data
                in connection with our website, app, and other products and
                services (the "Services"). Your use of the Services is subject
                to this Policy and our Terms of Service.
              </p>
            </FadeInSection>
            <FadeInSection>
              <div className="policies-wrap">
                <p>High-Level Summary</p>
                <p
                  className="mt-text2 redHat-regular abt-p-text"
                  style={{
                    textAlign: "left",
                    color: "rgba(255, 255, 255, 0.8)",
                  }}
                >
                  Trendx.app is a blockchain banking and investment platform
                  that operates (www.trendx.app) and other products and
                  services. We comply with applicable laws and regulations. We
                  do not collect and store personal data, such as first name,
                  last name, street address, date of birth, email address, or IP
                  address, in connection with your use of the Services. We
                  collect non-identifiable data, such as public on-chain data,
                  and limited off-chain data like device type, browser version,
                  etc. This is to help drive production vision, not track users.
                </p>
              </div>
            </FadeInSection>
            <FadeInSection>
              <div className="policies-wrap">
                <p>Data We Collect</p>
                <p
                  className="mt-text2 redHat-regular abt-p-text"
                  style={{
                    textAlign: "left",
                    color: "rgba(255, 255, 255, 0.8)",
                  }}
                >
                  We do not maintain user accounts and do not collect and store
                  personal data. When you interact with the Services, we collect
                  only: <br />
                  Publicly-available blockchain dataInformation from
                  localStorage and other tracking technologies Information from
                  other sources (e.g., service providers) Survey or usability
                  information (if you participate) Correspondence (e.g., email,
                  customer support)Biographical information (if you apply for a
                  job)
                </p>
              </div>
            </FadeInSection>
            <FadeInSection>
              <div className="policies-wrap">
                <p>How We Use Data</p>
                <p
                  className="mt-text2 redHat-regular abt-p-text"
                  style={{
                    textAlign: "left",
                    color: "rgba(255, 255, 255, 0.8)",
                  }}
                >
                  We use the data we collect in accordance with your
                  instructions, including any applicable terms in our Terms of
                  Service, and as required by law.
                  <br />
                  We may also use data for:
                  <br />
                  Providing the Services Customer support Safety and security
                  Legal compliance Aggregated data
                </p>
              </div>
            </FadeInSection>
            <FadeInSection>
              <div className="policies-wrap">
                <p>How We Share Data</p>
                <p
                  className="mt-text2 redHat-regular abt-p-text"
                  style={{
                    textAlign: "left",
                    color: "rgba(255, 255, 255, 0.8)",
                  }}
                >
                  We may share or disclose the data we collect: <br /> With
                  service providers
                  To comply with our legal obligations
                  For safety and security reasons
                  In the event of a business change (e.g., merger, acquisition)
                  With your consent
                </p>
              </div>
            </FadeInSection>
            <FadeInSection>
              <div className="policies-wrap">
                <p>Third-Party Cookies</p>
                <p
                  className="mt-text2 redHat-regular abt-p-text"
                  style={{
                    textAlign: "left",
                    color: "rgba(255, 255, 255, 0.8)",
                  }}
                >
                  We use services provided by third parties that use tracking
                  technology such as cookies, devicelD, and localStorage. You
                  can opt out of having your online activity and device data
                  collected through these third-party services.
                </p>
              </div>
            </FadeInSection>
            <FadeInSection>
              <div className="policies-wrap">
                <p>Third-Party Links and Sites</p>
                <p
                  className="mt-text2 redHat-regular abt-p-text"
                  style={{
                    textAlign: "left",
                    color: "rgba(255, 255, 255, 0.8)",
                  }}
                >
                  The Services may include links to websites, platforms, and
                  other services not operated or controlled by us. Please note
                  that when you interact with these other parties, they may
                  independently collect information about you.
                </p>
              </div>
            </FadeInSection>
            <FadeInSection>
              <div className="policies-wrap">
                <p>Security</p>
                <p
                  className="mt-text2 redHat-regular abt-p-text"
                  style={{
                    textAlign: "left",
                    color: "rgba(255, 255, 255, 0.8)",
                  }}
                >
                  We implement and maintain reasonable administrative, physical,
                  and technical security safeguards to help protect data from
                  loss, theft, misuse, unauthorized access, disclosure,
                  alteration, and destruction.
                </p>
              </div>
            </FadeInSection>
            <FadeInSection>
              <div className="policies-wrap">
                <p>Age Requirements</p>
                <p
                  className="mt-text2 redHat-regular abt-p-text"
                  style={{
                    textAlign: "left",
                    color: "rgba(255, 255, 255, 0.8)",
                  }}
                >
                 As defined by the (U.S. Children’s Privacy Protection Act, or “COPPA”) from children. If you believe we have received personal information about a child under the age of 18, please contact us at tokenize@trendx.app .
                </p>
              </div>
            </FadeInSection>
          </div>
        </div>
      </section>
    </div>
  );
}
