import React, { useState, useEffect } from "react";
import "../css/style.css";
import SideBar from "../components/SideBar";
import ProfileNav from "../components/ProfileNav";
import { Link, useLocation, useNavigate } from "react-router-dom";
import FadeInSection from "../components/FadeIn";
import paperClip from "../assests/images/papperclip.svg";
import emptyDoc from "../assests/images/empty-doc.svg";
import Logout, { handleLogout } from "../components/Logout";
import checkmark from "../assests/images/checkmark.svg";
import dropDown from "../assests/images/dropdown.svg";
import dropUp from "../assests/images/dropUp.svg";
import star from "../assests/images/noto_star.svg";
import crCard from "../assests/images/crCard.svg";
import masterCard from "../assests/images/masterCard.svg";
import Input1 from "../components/Input1";
import visa from "../assests/images/visa.svg";
import AE from "../assests/images/AE.svg";
import Btn from "../components/Btn";
import { useMediaQuery } from "react-responsive";

export default function PaymentInfoScreen() {
  const isMobile =  useMediaQuery({ maxWidth: 767 });
  const location = useLocation();
  const fromSupport = location.state?.fromBlog || false;

  const [cPasswordVisible, setCPasswordVisible] = useState(false);
  const [nPasswordVisible, setNPasswordVisible] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [plan, setPlan] = useState("subscribed");
  const navigate = useNavigate();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [pop, setPop] = useState(false);
  const handleDropdownToggle = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  useEffect(() => {
    // Example: Reset scroll position when navigating to this page
    window.scrollTo(0, 0);
  }, []);
  const subBenefit = [
    "Access subscriber-only content on TrendX",
    "Get blockchain investment recommendations",
    "Get timely and exclusive market insights",
    "Access to detailed blockchain case studies",
    "Read exclusive interviews with successful investors",
    "Achieve your goals in the blockchain space ",
  ];

  return (
    <section className="dashboard-layout">
      <div className="left-layout">
        <SideBar logoutClick={() => handleLogout()} fromBlog={fromSupport} />
      </div>
      <div className="left-spacer"></div>
      <div className="body-layout" style={{ height: "auto" }}>
        <div className="profile-nav-cont">
        {isMobile ? (
            <>
              <div className="sub-profile-nav">
                {" "}
                <Link
                  to=" "
                  onClick={(e) => {
                    e.preventDefault();
                    navigate(-1);
                  }}
                  className="blogArrow-back"
                  style={{ marginTop: 0, paddingTop: 0 }}
                >
                  <svg
                    width="40"
                    height="40"
                    viewBox="0 0 40 40"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M13.3333 31.6667C13.3333 30.4301 12.1117 28.5834 10.875 27.0334C9.285 25.0334 7.385 23.2884 5.20667 21.9567C3.57334 20.9584 1.59333 20.0001 9.53674e-07 20.0001M9.53674e-07 20.0001C1.59333 20.0001 3.575 19.0417 5.20667 18.0434C7.385 16.7101 9.285 14.9651 10.875 12.9684C12.1117 11.4167 13.3333 9.56675 13.3333 8.33341M9.53674e-07 20.0001L40 20.0001"
                      stroke="white"
                      stroke-width="2"
                    />
                  </svg>
                </Link>
              </div>
            </>
          ) : (
            <ProfileNav />
          )}
        </div>
        {/* <div className="profile-nav-topper"></div> */}
        <div className="main-body ppr-sec">
          <p className="pro-fp cp">Payment information</p>
          <div className="asc-wrap">
            {/* current sub */}
            <div className="sub-page" style={{ marginTop: 0, width: "100%" }}>
              <div
                className="modal-header py-modal-header"
                style={{ border: "none", justifyContent: "flex-start" }}
              >
                <img className="brand-logo" src={masterCard} alt="" />
                <img className="brand-logo" src={visa} alt="" />
                <img className="brand-logo" src={AE} alt="" />
              </div>

              <div className="py-inputs-cont">
                <Input1
                  label="Card number"
                  placeholder="0000 0000 0000 0000"
                  labelStyle={{ fontWeight: "400" }}
                  inputWrapStyle={{ height: 56 }}
                />
                <div className="cvv-cont">
                  <div className="cvv-input-cont1">
                    <Input1
                      label="Expiration"
                      placeholder="MM/YY"
                      labelStyle={{ fontWeight: "400" }}
                      inputWrapStyle={{ height: 56 }}
                    />
                  </div>
                  <div className="cvv-input-cont1">
                    <Input1
                      label="Security Code (CVV)"
                      placeholder="000"
                      labelStyle={{ fontWeight: "400" }}
                      inputWrapStyle={{ height: 56 }}
                    />
                  </div>
                </div>

                <button
                  className="pay-btn"
                  onClick={() => {
                    setPop(true);
                  }}
                >
                  Pay now
                </button>
                <p className="py-method">
                  Your payment method will be charged a recurring{" "}
                  <span>$11.88 yearly</span> fee, unless you decide to cancel.
                  No refunds for subscriptions canceled between billing cycles.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Logout />
    </section>
  );
}
