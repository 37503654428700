import React, { useState, useEffect } from "react";
import "../css/style.css";
import SideBar from "../components/SideBar";
import ProfileNav from "../components/ProfileNav";
import postImg1 from "../assests/images/postImg1.svg";
import postImg2 from "../assests/images/postImg2.svg";
import postImg3 from "../assests/images/postImg3.svg";
import { Link, useLocation } from "react-router-dom";
import FadeInSection from "../components/FadeIn";
import paperClip from "../assests/images/papperclip.svg";
import emptyDoc from "../assests/images/empty-doc.svg";
import FAQ from "../components/Faq";
import Logout, { handleLogout } from "../components/Logout";

export default function FAQScreen() {
  const location = useLocation();
  const fromSupport = location.state?.fromSupport || false;
  useEffect(() => {
    // Scroll to the top when the component mounts
    window.scrollTo(0, 0);
  }, []);

  return (
    <section className="dashboard-layout">
      <div className="left-layout">
        <SideBar fromSupport={fromSupport} logoutClick={() => handleLogout()} />
      </div>
      <div className="left-spacer"></div>
      <div className="body-layout">
        <div className="profile-nav-cont">
          <ProfileNav fromSupport={fromSupport}/>
        </div>
        {/* <div className="profile-nav-topper"></div> */}
        <div className="main-body ad-main-body" style={{ height: "auto" }}>
          <p className="g-text2 bd-text fq-text">FAQs</p>
          <FAQ Class="faq-cont2" />
        </div>
      </div>
      <Logout />
    </section>
  );
}
