import React, { useState, useEffect } from "react";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import "../css/style.css";
import { Link, useNavigate, useLocation } from "react-router-dom";
import star from "../assests/images/noto_star.svg";
import checkmark from "../assests/images/checkmark.svg";
import dropDown from "../assests/images/dropdown.svg";
import dropUp from "../assests/images/dropUp.svg";
import masterCard from "../assests/images/masterCard.svg";
import visa from "../assests/images/visa.svg";
import AE from "../assests/images/AE.svg";
import Input1 from "../components/Input1";
import PopModal from "../components/PopModal";
import { AddCard, GetUserDetails, Subscribe } from "../RequestHandlers/User";

export default function PaymentScreen() {
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [plan, setPlan] = useState("yearly");
  const [pop, setPop] = useState(true);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [id, setId] = useState("");

  const handleDropdownToggle = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const getUserDetails = async () => {
    try {
      const userDetails = await GetUserDetails();
      console.log(userDetails);

      setEmail(userDetails.email);
      setId(userDetails.id);
    } catch (error) {
      console.log(error);
    }
  };


  const cardStyle = {
    style: {
      base: {
        color: "#00ff00",
        fontFamily: "Arial, sans-serif",
        fontSmoothing: "antialiased",
        fontSize: "16px",
        "::placeholder": {
          color: "#aab7c4",
        },
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a",
      },
    },
  };

  const location = useLocation();

  useEffect(() => {
    getUserDetails();
    window.scrollTo(0, 0);
  }, []);

  const subBenefit = [
    "Access subscriber-only content on TrendX",
    "Get blockchain investment recommendations",
    "Get timely and exclusive market insights",
    "Access to detailed blockchain case studies",
    "Read exclusive interviews with successful investors",
    "Achieve your goals in the blockchain space ",
  ];

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    try {
      const sub = await Subscribe({
        subcriptionId: 1,
        userId: id,
        subcriptionType: "monthly",
      });
      console.log(sub.clientSecrect);
      console.log(sub);
    } catch (error) {
      console.log(error);
    }
    
  };

  return (
    <section className="blogPostSection">
      <div className="blog-nav blog-post-nav">
        <Link
          to=" "
          onClick={(e) => {
            e.preventDefault();
            navigate(-1);
          }}
          className="blogArrow-back">
          <svg
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M13.3333 31.6667C13.3333 30.4301 12.1117 28.5834 10.875 27.0334C9.285 25.0334 7.385 23.2884 5.20667 21.9567C3.57334 20.9584 1.59333 20.0001 9.53674e-07 20.0001M9.53674e-07 20.0001C1.59333 20.0001 3.575 19.0417 5.20667 18.0434C7.385 16.7101 9.285 14.9651 10.875 12.9684C12.1117 11.4167 13.3333 9.56675 13.3333 8.33341M9.53674e-07 20.0001L40 20.0001"
              stroke="white"
              stroke-width="2"
            />
          </svg>
        </Link>
      </div>
      <div className="sub-topper"></div>
      <div className="bpb-section">
        <div className="bpb-inner-wrap py-inner-wrap">
          <p className="sub-text-head pay">Payment</p>
          <div
            className={"active-page-cont"}
            style={{ flexDirection: "column" }}>
            <div
              className="sub-page pay-plan"
              style={{ marginTop: 0, width: "100%" }}>
              <div className="modal-header">
                <div className="mh-text-cont">
                  <div className="star-wrap">
                    <img src={star} alt="" />
                    <p>TX premium</p>
                  </div>
                  {plan === "monthly" ? (
                    <p className="price">$4.99/month</p>
                  ) : (
                    <p className="price">
                      {" "}
                      <span className="old-price">$59.88</span>$11.88/year
                    </p>
                  )}
                </div>
                <Link className="change-plan" to={"/subscriptionScreen"}>
                  Change plan
                </Link>
              </div>
              <button className="pay-dropdown" onClick={handleDropdownToggle}>
                What’s included
                <img
                  src={isDropdownOpen ? dropUp : dropDown}
                  alt=""
                  style={{ marginLeft: 12 }}
                />
              </button>
              {isDropdownOpen && (
                <div className="modal-body">
                  {subBenefit.map((item, index) => {
                    return (
                      <div
                        className="sub-ben"
                        style={{
                          marginBottom:
                            index === subBenefit.length - 1 ? 0 : "",
                        }}>
                        <img src={checkmark} alt="" />
                        <p className="py-sub-benefit">{item}</p>
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
            <div className="pcd-cont">
              <p>Pay with credit or debit card</p>
            </div>
            <div className="sub-page" style={{ marginTop: 0, width: "100%" }}>
              <div
                className="modal-header py-modal-header"
                style={{ border: "none", justifyContent: "flex-start" }}>
                <img className="brand-logo" src={masterCard} alt="" />
                <img className="brand-logo" src={visa} alt="" />
                <img className="brand-logo" src={AE} alt="" />
              </div>

              <div className="py-inputs-cont">
                <Input1
                  label="Cardholder Name"
                  placeholder="Cardholder Name"
                  labelStyle={{ fontWeight: "400" }}
                  inputWrapStyle={{ height: 56 }}
                  value={name}
                  onChange={(e: any) => setName(e.target.value)}
                />
                <CardElement options={cardStyle} />
                <button className="pay-btn" onClick={handleSubmit}>
                  Subscribe
                </button>
                <p className="py-method">
                  Your payment method will be charged a recurring{" "}
                  <span>$11.88 yearly</span> fee, unless you decide to cancel.
                  No refunds for subscriptions canceled between billing cycles.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {pop && (
        <PopModal
          btnText={"Go to blog"}
          onclick={() => {
            setPop(false);
            window.location.href = "/blogDashboard";
          }}
          popTextContent={
            <div>
              <p className="pop-text1">Payment successful</p>
              <p className="pop-text2">
                Your payment was successful, you now have <br /> access to all
                our research reports.
              </p>
            </div>
          }
        />
      )}
    </section>
  );
}
