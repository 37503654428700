import React, { useState, CSSProperties } from "react";
import "../css/style.css";
import hamburg from "../assests/images/hamburg.svg";
import close from "../assests/images/close.svg";
import searchIcon from "../assests/images/search.svg";
import bell from "../assests/images/bell2.png";
import logo from '../assests/images/Logo.png';
import { Link, NavLink, useNavigate } from "react-router-dom";
import info2 from "../assests/images/info2.svg";
import activeInfo from "../assests/images/active-info.svg";
import logout from "../assests/images/logout1.svg";
import profile from "../assests/images/profile.svg";
import dropDown from "../assests/images/dropdown.svg";
import dropUp from "../assests/images/dropUp.svg";

interface PProp {
  fromSupport?: any;
  logoutClick?: any;
  fromBlog?: any;
  userName?: true | false;
  searchInput?: any;
  fromProfile?: any;
  fromSubscription?: any;
  navStyle?: CSSProperties;
  notiStyle?: CSSProperties;
  newName?: string;
}

export default function MobileProfileNav({
  logoutClick,
  userName = true,
  searchInput,
  fromSupport,
  fromBlog,
  fromProfile,
  fromSubscription,
  navStyle,
  notiStyle,
  newName
}: PProp) {
  const [showMenu, setShowMenu] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const navigate = useNavigate();
  return (
    <>
      <div className="m-pro-nav" style={navStyle}>
        <button
          style={{ border: "none", background: "none" }}
          onClick={() => setShowMenu(true)}
        >
          <img src={hamburg} alt="" />
        </button>
        <p
          className="user-name"
          style={{ marginLeft: 16, display: userName === false ? "none" : "" }}
        >
          Welcome, {newName} ✨
        </p>
        {searchInput}
        <div className="share-noti" style={notiStyle}>
          <button
            onClick={() => {
              navigate("/mobileSearch");
            }}
            style={{
              border: "none",
              background: "none",
              cursor: "pointer",
              marginRight: 16,
              display: userName == false ? "none" : "",
            }}
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11 19C15.4183 19 19 15.4183 19 11C19 6.58172 15.4183 3 11 3C6.58172 3 3 6.58172 3 11C3 15.4183 6.58172 19 11 19Z"
                stroke="#FFFFFF"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M20.9992 21.0002L16.6992 16.7002"
                stroke="#FFFFFF"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </button>
          <button
            style={{ border: "none", background: "none" }}
            onClick={() => {
              navigate("/mNotification");
            }}
          >
            <img
              src={bell}
              alt=""
              style={{ width: 24, height: 24, cursor: "pointer" }}
            />
          </button>
        </div>
      </div>
      {showMenu && (
        <div className="blog-menu">
          <div className="blog-menu-nav">
            <img src={logo} alt="" style={{ width: 114, height: 30 }} />
            <button className="menu-toggle" onClick={() => setShowMenu(false)}>
              <img src={close} alt="" />
            </button>
          </div>
          <div className="blog-menu-body">
            <button
              className="menu-logout-btn"
              style={{ marginBottom: 24 }}
              onClick={toggleDropdown}
            >
              <img src={profile} alt="" />
              Account
              <img
                src={isDropdownOpen ? dropUp : dropDown}
                alt=""
                style={{ width: 18, height: 18, marginLeft: 10 }}
              />
            </button>
            {isDropdownOpen && (
              <>
                <div className="acc-drop-content">
                  <button
                    className="ppr-btn mppr-btn"
                    onClick={() =>
                      navigate("/profileScreen", { state: { fromBlog: true } })
                    }
                  >
                    Profile details
                  </button>
                  <button
                    className="ppr-btn mppr-btn"
                    onClick={() =>
                      navigate("/userSupscription", {
                        state: { fromBlog: true },
                      })
                    }
                  >
                    Subscription
                  </button>
                </div>
              </>
            )}
            <NavLink
              className={({ isActive }) =>
                isActive ? "menu-links active-menu-link" : "menu-links"
              }
              to={"/blogDashboard"}
            >
              {({ isActive }) => (
                <>
                  <svg
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M3 9.75H7.5C7.69891 9.75 7.88968 9.67098 8.03033 9.53033C8.17098 9.38968 8.25 9.19891 8.25 9V3C8.25 2.80109 8.17098 2.61032 8.03033 2.46967C7.88968 2.32902 7.69891 2.25 7.5 2.25H3C2.80109 2.25 2.61032 2.32902 2.46967 2.46967C2.32902 2.61032 2.25 2.80109 2.25 3V9C2.25 9.19891 2.32902 9.38968 2.46967 9.53033C2.61032 9.67098 2.80109 9.75 3 9.75ZM2.25 15C2.25 15.1989 2.32902 15.3897 2.46967 15.5303C2.61032 15.671 2.80109 15.75 3 15.75H7.5C7.69891 15.75 7.88968 15.671 8.03033 15.5303C8.17098 15.3897 8.25 15.1989 8.25 15V12C8.25 11.8011 8.17098 11.6103 8.03033 11.4697C7.88968 11.329 7.69891 11.25 7.5 11.25H3C2.80109 11.25 2.61032 11.329 2.46967 11.4697C2.32902 11.6103 2.25 11.8011 2.25 12V15ZM9.75 15C9.75 15.1989 9.82902 15.3897 9.96967 15.5303C10.1103 15.671 10.3011 15.75 10.5 15.75H15C15.1989 15.75 15.3897 15.671 15.5303 15.5303C15.671 15.3897 15.75 15.1989 15.75 15V9.75C15.75 9.55109 15.671 9.36032 15.5303 9.21967C15.3897 9.07902 15.1989 9 15 9H10.5C10.3011 9 10.1103 9.07902 9.96967 9.21967C9.82902 9.36032 9.75 9.55109 9.75 9.75V15ZM10.5 7.5H15C15.1989 7.5 15.3897 7.42098 15.5303 7.28033C15.671 7.13968 15.75 6.94891 15.75 6.75V3C15.75 2.80109 15.671 2.61032 15.5303 2.46967C15.3897 2.32902 15.1989 2.25 15 2.25H10.5C10.3011 2.25 10.1103 2.32902 9.96967 2.46967C9.82902 2.61032 9.75 2.80109 9.75 3V6.75C9.75 6.94891 9.82902 7.13968 9.96967 7.28033C10.1103 7.42098 10.3011 7.5 10.5 7.5Z"
                      fill={isActive ? "#B981F1" : "white"}
                      fill-opacity="1"
                    />
                  </svg>
                  Blog
                </>
              )}
            </NavLink>
            <NavLink
              className={({ isActive }) =>
                isActive ? "menu-links active-menu-link" : "menu-links"
              }
              to={"/bookmarkScreen"}
            >
              {({ isActive }) => (
                <>
                  <svg
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M14.9775 4.74097V14.7385C14.9608 15.079 14.8504 15.4084 14.6586 15.6903C14.4667 15.9721 14.2007 16.1956 13.89 16.336C13.5839 16.471 13.2475 16.5228 12.915 16.486C12.5808 16.4444 12.2644 16.3121 12 16.1035L9.4425 14.206C9.30741 14.1111 9.14634 14.0602 8.98125 14.0602C8.81616 14.0602 8.65509 14.1111 8.52 14.206L5.9475 16.126C5.68646 16.3292 5.37565 16.4587 5.0475 16.501H4.8225C4.56362 16.4999 4.30794 16.4436 4.0725 16.336C3.772 16.1982 3.51325 15.9835 3.3225 15.7135C3.13382 15.4351 3.02252 15.1115 3 14.776V4.75672C3.01452 4.31376 3.11633 3.87802 3.2996 3.47449C3.48287 3.07097 3.74399 2.70758 4.06799 2.40518C4.39199 2.10278 4.77249 1.86731 5.18769 1.71228C5.60288 1.55724 6.04459 1.48568 6.4875 1.50172H11.535C12.4231 1.47536 13.2856 1.80103 13.9346 2.40777C14.5836 3.0145 14.9666 3.85314 15 4.74097H14.9775Z"
                      fill={isActive ? "#B981F1" : "white"}
                      fill-opacity="1"
                    />
                  </svg>
                  Bookmarks
                </>
              )}
            </NavLink>
            <NavLink
              className={({ isActive }) =>
                isActive ? "menu-links active-menu-link" : "menu-links"
              }
              to={"/advisoryScreen"}
              style={{ marginBottom: 56 }}
            >
              {({ isActive }) => (
                <>
                  <svg
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M5.90625 9C6.72676 9 7.51367 8.67405 8.09386 8.09386C8.67405 7.51367 9 6.72676 9 5.90625C9 5.08574 8.67405 4.29883 8.09386 3.71864C7.51367 3.13845 6.72676 2.8125 5.90625 2.8125C5.08574 2.8125 4.29883 3.13845 3.71864 3.71864C3.13845 4.29883 2.8125 5.08574 2.8125 5.90625C2.8125 6.72676 3.13845 7.51367 3.71864 8.09386C4.29883 8.67405 5.08574 9 5.90625 9ZM12.9375 9C13.5342 9 14.1065 8.76295 14.5285 8.34099C14.9504 7.91903 15.1875 7.34674 15.1875 6.75C15.1875 6.15326 14.9504 5.58097 14.5285 5.15901C14.1065 4.73705 13.5342 4.5 12.9375 4.5C12.3408 4.5 11.7685 4.73705 11.3465 5.15901C10.9246 5.58097 10.6875 6.15326 10.6875 6.75C10.6875 7.34674 10.9246 7.91903 11.3465 8.34099C11.7685 8.76295 12.3408 9 12.9375 9ZM2.8125 10.125C2.36495 10.125 1.93572 10.3028 1.61926 10.6193C1.30279 10.9357 1.125 11.3649 1.125 11.8125V11.8969C1.125 11.8969 1.125 15.1875 5.90625 15.1875C10.6875 15.1875 10.6875 11.8969 10.6875 11.8969V11.8125C10.6875 11.3649 10.5097 10.9357 10.1932 10.6193C9.87678 10.3028 9.44755 10.125 9 10.125H2.8125ZM12.9375 14.0625C12.1185 14.0625 11.4705 13.9511 10.9569 13.7739C11.1999 13.3802 11.3394 13.0005 11.4182 12.6939C11.4756 12.4736 11.5119 12.2484 11.5268 12.0212L11.529 11.961L11.5296 11.9374V11.8125C11.5308 11.1897 11.3013 10.5886 10.8855 10.125H15.5211C15.6988 10.1249 15.8749 10.1599 16.0391 10.2279C16.2034 10.2958 16.3527 10.3955 16.4784 10.5212C16.6041 10.6469 16.7039 10.7961 16.7719 10.9603C16.84 11.1246 16.875 11.3006 16.875 11.4784C16.875 11.4784 16.875 14.0625 12.9375 14.0625Z"
                      fill={isActive ? "#B981F1" : "white"}
                      fill-opacity="1"
                    />
                  </svg>
                  Advisory
                </>
              )}
            </NavLink>
            <NavLink
              className={({ isActive }) =>
                isActive ? "menu-links active-menu-link" : "menu-links"
              }
              to={"/dashAboutScreen"}
            >
              {({ isActive }) => (
                <>
                  <img
                    src={isActive ? activeInfo : info2}
                    alt=""
                    style={{ marginRight: 10 }}
                  />
                  About us
                </>
              )}
            </NavLink>
            <NavLink
              className={({ isActive }) =>
                isActive || fromSupport
                  ? "menu-links active-menu-link"
                  : "menu-links"
              }
              to={"/support"}
            >
              {({ isActive }) => (
                <>
                  <svg
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M14.04 11.07C14.3025 10.4325 14.445 9.75002 14.445 9.00002C14.445 8.46002 14.3625 7.94252 14.22 7.46252C13.7325 7.57502 13.2225 7.63502 12.69 7.63502C11.5995 7.63619 10.5247 7.37516 9.55615 6.87394C8.58764 6.37271 7.75382 5.64599 7.125 4.75502C6.45232 6.38265 5.18334 7.69187 3.5775 8.41502C3.5475 8.60252 3.5475 8.80502 3.5475 9.00002C3.5475 9.71605 3.68853 10.4251 3.96255 11.0866C4.23656 11.7481 4.63819 12.3492 5.1445 12.8555C6.16704 13.8781 7.55391 14.4525 9 14.4525C9.7875 14.4525 10.545 14.28 11.2275 13.9725C11.655 14.79 11.85 15.195 11.835 15.195C10.605 15.6075 9.6525 15.81 9 15.81C7.185 15.81 5.4525 15.0975 4.1775 13.815C3.402 13.0419 2.82553 12.0923 2.4975 11.0475H1.5V7.63502H2.3175C2.56518 6.42941 3.13462 5.31333 3.96539 4.40521C4.79616 3.4971 5.85728 2.83083 7.03615 2.47709C8.21501 2.12335 9.46765 2.09535 10.6611 2.39605C11.8546 2.69675 12.9445 3.31494 13.815 4.18502C14.7602 5.12646 15.4049 6.3271 15.6675 7.63502H16.5V11.0475H16.455L13.785 13.5L9.81 13.05V11.7975H13.4325L14.04 11.07ZM6.9525 8.82752C7.1775 8.82752 7.395 8.91752 7.5525 9.08252C7.71078 9.2421 7.7996 9.45776 7.7996 9.68252C7.7996 9.90728 7.71078 10.1229 7.5525 10.2825C7.395 10.44 7.1775 10.53 6.9525 10.53C6.48 10.53 6.0975 10.155 6.0975 9.68252C6.0975 9.21002 6.48 8.82752 6.9525 8.82752ZM11.04 8.82752C11.5125 8.82752 11.8875 9.21002 11.8875 9.68252C11.8875 10.155 11.5125 10.53 11.04 10.53C10.5675 10.53 10.185 10.155 10.185 9.68252C10.185 9.45576 10.2751 9.23829 10.4354 9.07794C10.5958 8.9176 10.8132 8.82752 11.04 8.82752Z"
                      fill={isActive || fromSupport ? "#B981F1" : "white"}
                      fill-opacity="1"
                    />
                  </svg>
                  Help/Support
                </>
              )}
            </NavLink>
            <button className="menu-logout-btn" onClick={logoutClick}>
              <img src={logout} alt="" onClick={logoutClick} />
              Log out
            </button>
          </div>
        </div>
      )}
    </>
  );
}
