import React, { useState, useEffect, useRef } from "react";
import "../css/style.css";
import "../css/responsive.css";
import { Link, useNavigate } from "react-router-dom";
import paperClip from "../assests/images/papperclip.svg";
import postImg1 from "../assests/images/postImg1.svg";
import postImg2 from "../assests/images/postImg2.svg";
import postImg3 from "../assests/images/postImg3.svg";
import FadeInSection from "../components/FadeIn";
import { useMediaQuery } from "react-responsive";
import { GetBlogCategories, GetAllBlogs } from "../RequestHandlers/Blogs";
import { formatDate } from "../components/FormatDate";
import emptyIcon from "../assests/images/emptyIcon.svg";
import Loader from "../components/Loader";
import Pagination from "./Pagination";

export default function BlogScreen() {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const [catID, setCatID] = useState(0);
  const [premium, setPremium] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [blogCat, setBlogCat] = useState([]);
  const [allBlogs, setAllBlogs] = useState([]);
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();
  // const tabLinks = [
  //   "All",
  //   "Tokenization",
  //   "DeFi",
  //   "BlockChain Tech",
  //   "Investment",
  //   "Industry Insights",
  // ];
  const containerRef = useRef(null);
  const [isOverflowing, setIsOverflowing] = useState(false);

  const scrollAmount = 100; // Adjust scroll amount as needed
  const isSafari = () => {
    return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  };

  const scrollLeft = () => {
    if (containerRef.current) {
      // @ts-ignore
      containerRef.current.scrollBy({
        top: 0,
        left: -scrollAmount,
        behavior: isSafari() ? "auto" : "smooth",
      });
    }
  };

  const scrollRight = () => {
    if (containerRef.current) {
      // @ts-ignore
      containerRef.current.scrollBy({
        top: 0,
        left: scrollAmount,
        behavior: isSafari() ? "auto" : "smooth",
      });
    }
  };

  const checkOverflow = () => {
    if (containerRef.current) {
      const isOverflowing =
        // @ts-ignore
        containerRef.current.scrollWidth > containerRef.current.clientWidth;
      setIsOverflowing(isOverflowing);
    }
  };

  useEffect(() => {
    checkOverflow();
    window.addEventListener("resize", checkOverflow);

    const observer = new MutationObserver(() => {
      checkOverflow();
    });

    if (containerRef.current) {
      observer.observe(containerRef.current, {
        childList: true,
        subtree: true,
      });
    }

    return () => {
      window.removeEventListener("resize", checkOverflow);
      observer.disconnect();
    };
  }, []);
  const [activeTab, setActiveTab] = useState("All");

  // @ts-ignore
  const truncateText = (text, maxLength) => {
    let charCount = 0;
    let truncatedText = "";

    for (let char of text) {
      if (char !== " ") {
        charCount++;
      }
      if (charCount <= maxLength) {
        truncatedText += char;
      } else {
        truncatedText += "...";
        break;
      }
    }

    return truncatedText;
  };

  const getBlogCategories = async () => {
    try {
      const blogCategories = await GetBlogCategories();
      // @ts-ignore
      const allCategory = { id: null, name: "All" }; // Define your "All" category
      // @ts-ignore
      setBlogCat([allCategory, ...blogCategories]);
      if (blogCategories) {
        setLoader(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getllBlogs = async (pageNumber = 1) => {
    try {
      const fetchAllBLogs = await GetAllBlogs(catID, premium, pageNumber);
      setAllBlogs(fetchAllBLogs.items);
      // console.log(fetchAllBLogs);

      setTotalPages(fetchAllBLogs.meta.totalPages); // Assuming the API response includes the total pages
    } catch (error) {
      console.log(error);
    }
  };

  // @ts-ignore
  const handleReadMoreClick = (slug, category, catId) => {
    navigate("/blogPostScreen", { state: { slug, category, catId } }); // Pass the blog item as state
  };

  useEffect(() => {
    // Scroll to the top when the component mounts
    window.scrollTo(0, 0);
    getBlogCategories();
    getllBlogs();
  }, []);

  useEffect(() => {
    getllBlogs(page);
  }, [page, catID]);

  return (
    <section className="blog-section">
      <div className="blog-nav blog-nav1">
        <Link to="/" className="blogArrow-back">
          <svg
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M13.3333 31.6667C13.3333 30.4301 12.1117 28.5834 10.875 27.0334C9.285 25.0334 7.385 23.2884 5.20667 21.9567C3.57334 20.9584 1.59333 20.0001 9.53674e-07 20.0001M9.53674e-07 20.0001C1.59333 20.0001 3.575 19.0417 5.20667 18.0434C7.385 16.7101 9.285 14.9651 10.875 12.9684C12.1117 11.4167 13.3333 9.56675 13.3333 8.33341M9.53674e-07 20.0001L40 20.0001"
              stroke="white"
              stroke-width="2"
            />
          </svg>
        </Link>
        <p className="gradient-text blog-text" style={{ margin: 0 }}>
          Blog
        </p>
      </div>
      <div className="blog-topper"></div>
      <div className="tab-Bar">
        {/* {isOverflowing && (
          <button
            className="movement-arrow"
            style={{
              background: "none",
              border: "none",
              color: "#fff",
              fontSize: 20,
              marginRight: 30,
            }}
            onClick={scrollLeft}
          >
            ←
          </button>
        )} */}
        <div className="tab-link-cont b-tab-link-cont" ref={containerRef}>
          {blogCat.map((item, index) => {
            return (
              <p
                style={{
                  // @ts-ignore
                  color: "white",
                  // @ts-ignore
                  fontWeight: activeTab === item.name ? 600 : 400,
                  marginRight: index === blogCat.length - 1 ? 0 : "",
                  cursor: "pointer",
                }}
                className="tab-links"
                onClick={() => {
                  // @ts-ignore
                  setActiveTab(item.name);
                  // @ts-ignore
                  setCatID(item.id);
                  // @ts-ignore
                  // getllBlogs();
                }}
              >
                {/* @ts-ignore */}
                {item.name}
              </p>
            );
          })}
        </div>
        {/* {isOverflowing && (
          <button
            className="movement-arrow"
            style={{
              background: "none",
              border: "none",
              color: "#fff",
              fontSize: 20,
              marginLeft: 30,
            }}
            onClick={scrollRight}
          >
            →
          </button>
        )} */}
      </div>

      <div className="blog-content">
        {allBlogs.length === 0 ? (
          <div className="empty-sec" style={{ background: "none" }}>
            <img src={emptyIcon} alt="" />
            <p className="pop-text1">No blog post</p>
            <p className="pop-text2">
              {activeTab === "All" ? (
                <p className="pop-text2">Oops, no blog post at the moment</p>
              ) : (
                <p className="pop-text2">No blog post under this category</p>
              )}
            </p>
          </div>
        ) : (
          <div className="main-blog-section">
            {allBlogs.map((item, index) => {
              const parser = new DOMParser();
              // @ts-ignore
              const doc = parser.parseFromString(item.body, "text/html");
              let firstParagraph = doc.querySelector("p");
              const firstImag = doc.querySelector("img");

              // Check if the first paragraph is empty or contains only whitespace
              // @ts-ignore
              if (!firstParagraph || firstParagraph.textContent.trim() === "") {
                const paragraphs = doc.querySelectorAll("p");
                if (paragraphs.length > 1) {
                  firstParagraph = paragraphs[1];
                }
              }
              // @ts-ignore

              return (
                <FadeInSection>
                  <div
                    className="blog-content-card"
                    onClick={(e) => {
                      e.preventDefault();
                      // @ts-ignore
                      handleReadMoreClick(
                        // @ts-ignore
                        item.slug,
                        // @ts-ignore
                        item.category.name,
                        // @ts-ignore
                        item.category.id
                      );
                    }}
                    style={{cursor: 'pointer'}}
                  >
                    <div className="blog-card-header">
                      <div className="bch-wrap">
                        <img src={paperClip} alt="" />
                        {/* @ts-ignore */}
                        <p className="bch-p">{item.category.name}</p>
                      </div>
                      {/* @ts-ignore */}
                      <p>{formatDate(item.created_at)}</p>
                    </div>
                    <div className="line-seperator"></div>
                    {/* @ts-ignore */}
                    <p className="blog-title">{item.title}</p>
                    <p className="blog-post-body">
                      {firstParagraph
                        ? truncateText(firstParagraph.textContent, 120)
                        : ""}{" "}
                      <span>
                        <Link
                          to={""}
                          onClick={(e) => {
                            e.preventDefault();
                            // @ts-ignore
                            handleReadMoreClick(
                              // @ts-ignore
                              item.slug,
                              // @ts-ignore
                              item.category.name,
                              // @ts-ignore
                              item.category.id
                            );
                          }}
                          className="readMore"
                        >
                          Read More
                        </Link>
                      </span>
                    </p>

                    <img
                      // @ts-ignore
                      src={firstImag ? firstImag.src : ""}
                      className="b-postImg b-postImg1"
                      alt=""
                    />
                  </div>
                </FadeInSection>
              );
            })}
          </div>
        )}
        {allBlogs.length === 0 ? (
          ""
        ) : (
          <Pagination page={page} totalPages={totalPages} setPage={setPage} />
        )}
      </div>
    </section>
  );
}
