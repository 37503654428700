import React from "react";
import "../css/style.css";

interface PProps {
  text?: string;
  onclick?: any;
}

export default function LongBtn({ text, onclick }: PProps) {
  return (
    <div className="consult-btn btn-sec" style={{marginTop: 0}} onClick={onclick}>
      <p className="redHat-medium">{text}</p>
      <svg
        width="56"
        height="56"
        viewBox="0 0 56 56"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="56" height="56" rx="28" fill="black" />
        <path
          d="M17.3334 28H20.6667M38.6667 28L30.6667 20M38.6667 28L30.6667 36M38.6667 28H24.6667"
          stroke="white"
          stroke-width="2.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </div>
  );
}
